import { useTheme } from "@emotion/react";
import {
  CircularProgress,
  Dialog,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";

import { Paper } from "@mui/material";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import { formatDate } from "examples/Tables/DataTable/utils";
import Confirmation from "examples/dialogs/Confirmation";
import SearchBar from "examples/searchBar/SearchBar";
import { Link } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import EmptyResult from "utils/emptyResult";
import { folderService } from "../service/folderService";
import FileModel from "./FileViewer";
import TextWithIcon from "./TextWithIcon";
import { folderAPI } from "api";
import { useGlobalState } from "Global";
import AiLoading from "./AiLoading";
import aiLoading from "assets/images/icons/ai-loading.svg";

const SearchFilesDialog = ({ open, handleClose }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;
  const searchRef = useRef(null);

  const [isLoading, setIsLoading] = useState({
    initial_response: false,
    llm_response: false,
  });

  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(null);

  const [searchResults, setSearchResults] = useState({
    initial_response: [],
    llm_response: [],
  });

  const [isVectorSearch, setIsVectorSearch] = useState(true);
  const [isFirstSearchDone, setIsFirstSearchDone] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [userDetails] = useGlobalState("userDetails");

  useEffect(() => {
    if (searchRef.current) searchRef.current.value = "";
    setIsFirstSearchDone(false);
    setIsLoading({
      initial_response: false,
      llm_response: false,
    });
    setSearchResults({
      initial_response: [],
      llm_response: [],
    });
  }, [isVectorSearch]);

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    folderAPI.searchFilesWS({
      token: `Bearer ${userDetails["access_token"]}`,
    });

  useEffect(() => {
    if (lastJsonMessage) {
      const { status, content, type } = lastJsonMessage;
      const tempType =
        type === "initial_response" ? "initial_response" : "llm_response";
      setSearchResults((pre) => ({
        ...pre,
        [tempType]: content || [],
      }));
      setIsLoading((pre) => ({
        ...pre,
        [tempType]: false,
      }));
    }
  }, [lastJsonMessage]);

  const sendRequestToWS = (body) => {
    setIsLoading({
      initial_response: true,
      llm_response: isVectorSearch,
    });
    if (readyState == 1) {
      sendJsonMessage(body);
    }
  };

  const searchFilesWS = () => {
    setIsFirstSearchDone(true);
    sendRequestToWS({
      type: "search_files",
      search_query: searchRef.current.value,
      vector_search: isVectorSearch,
    });
  };

  // const searchFile = () => {
  //   setIsLoading(true);
  //   folderService
  //     .searchFiles(searchRef.current.value, isVectorSearch)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setSearchResults(res.data);
  //       }
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const handleDeleteFile = (id) => {
    setIsLoading(true);
    folderService
      .deleteFile(id)
      .then((res) => {
        if (res.status == 200) {
          let tempSearchFiles = searchResults.initial_response.filter(
            (file) => file.file_id !== id
          );
          let tempLlmFiles = searchResults.llm_response.filter(
            (file) => file.file_id !== id
          );
          searchResults({
            initial_response: tempSearchFiles,
            llm_response: tempLlmFiles,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowDeleteFileDialog(null);
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{
          marginRight: "0%",
          marginLeft: "0%",
          width: "576px",
          padding: "1rem",
        }}
      >
        <MDBox>
          <SearchBar
            handleSearch={searchFilesWS}
            searchOnEnterPress={true}
            inputRef={searchRef}
            disabled={!(readyState == 1)}
            placeholder={"Search Files"}
            sx={{
              border: "none",
              backgroundColor: primary.light,
              padding: "5px 12px",
            }}
            fontColor={primary.main}
            fontSize={"1.1em"}
            // isLoading={isLoading}
            focusOnOpen={true}
            serchIconPosition={"right"}
          />
        </MDBox>
        <FormControlLabel
          sx={{
            "& .MuiTypography-root": {
              color: text.disabled,
              fontWeight: "500",
              paddingLeft: "5px",
              margin: 0,
            },
          }}
          control={
            <Switch
              onChange={(e, value) => {
                setIsVectorSearch(value);
              }}
              checked={isVectorSearch}
              sx={{
                "& .Mui-checked .MuiSwitch-thumb": {
                  borderColor: primary.main + " !important",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                  borderColor: primary.main + " !important",
                  backgroundColor: primary.main + " !important",
                },
              }}
            />
          }
          label="Search Based On Text Inside Document"
        />
        <MDBox
          sx={{
            minHeight: "200px",
            maxHeight: "calc(100vh - 200px)",
            overflow: "auto",
            position: "relative",
          }}
        >
          {isVectorSearch && isFirstSearchDone && (
            <MDBox my={1}>
              <MDBox sx={{ fontWeight: "bold" }} color={text.secondary}>
                AI Suggestions
              </MDBox>
              {isLoading.llm_response ? (
                <AiLoading
                  messages={[
                    "Requesting AI...",
                    "Analyzing data...",
                    "Getting results...",
                  ]}
                  time={1000}
                />
              ) : searchResults.llm_response.length === 0 ? (
                <MDBox>Oops!! Ai cannot find any suggestions</MDBox>
              ) : (
                <ListFiles
                  files={searchResults.llm_response}
                  setPreviewFile={setPreviewFile}
                />
              )}
            </MDBox>
          )}

          {isFirstSearchDone && (
            <MDBox my={1}>
              <MDBox
                sx={{
                  alignItems: "center",
                  gap: "1rem",
                }}
                color={text.secondary}
              >
                <MDBox color={text.secondary} sx={{ fontWeight: "bold" }}>
                  Search Results
                </MDBox>
                {isLoading.initial_response ? (
                  <MDBox
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <CircularProgress
                      sx={{
                        height: "1.3em !important",
                        width: "1.3em !important",
                      }}
                    />
                    <MDBox sx={{ marginTop: "2px" }} color={text.secondary}>
                      Searching...
                    </MDBox>
                  </MDBox>
                ) : searchResults.initial_response.length === 0 ? (
                  <MDBox color={text.secondary}>Oops!! no search results</MDBox>
                ) : (
                  <ListFiles
                    files={searchResults.initial_response}
                    setPreviewFile={setPreviewFile}
                  />
                )}
              </MDBox>
            </MDBox>
          )}
          {!isFirstSearchDone && (
            <MDBox
              mt={2}
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <MDBox sx={{ position: "relative" }}>
                {isVectorSearch ? (
                  <>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        fontSize: "4.5em",
                        color: primary.main,
                        position: "absolute",
                        top: "40%",
                        left: "-10px",
                      }}
                    >
                      search
                    </span>
                    <img src={aiLoading} height={"100px"} />
                  </>
                ) : (
                  <span
                    class="material-symbols-outlined"
                    style={{
                      fontSize: "5em",
                      color: primary.main,
                      marginTop: "1rem",
                    }}
                  >
                    manage_search
                  </span>
                )}
              </MDBox>
              <MDBox>
                {isVectorSearch
                  ? "Type and Press Enter to Search With AI"
                  : "Type and Press Enter to Search Files by Name"}
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Paper>
      {previewFile && (
        <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
          <FileModel file={previewFile} />
        </DialogWrapper>
      )}
      <Confirmation
        open={showDeleteFileDialog}
        onClose={() => {
          setShowDeleteFileDialog(null);
        }}
        onAccept={() => handleDeleteFile(showDeleteFileDialog?.file_id)}
        acceptLable={"Delete"}
        title={`Do you really want to delete File: ${showDeleteFileDialog?.filename}?`}
        isLoading={isLoading}
      />
    </Dialog>
  );
};

export default SearchFilesDialog;

const ListFiles = ({ files, setShowDeleteFileDialog, setPreviewFile }) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;

  return (
    <>
      {files.map((data) => (
        <MDBox
          py={1}
          sx={{
            display: "flex",
            gap: "1rem",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid " + grey[300],
          }}
        >
          <MDBox
            color={"inherit"}
            sx={{
              display: "flex",
              width: "100%",
              gap: ".5rem",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() =>
              setPreviewFile({
                name: data.filename,
                url: data.s3_url,
                id: data.file_id,
                rawFile: data?.rawFile,
              })
            }
          >
            <img
              src={folderService.getFileIcon(data.filename)}
              style={{ height: "1.3em" }}
            />
            <MDBox color={"inherit"} sx={{ width: "100%" }}>
              {data.filename}
            </MDBox>
          </MDBox>
          <MDBox sx={{ whiteSpace: "nowrap" }} color={text.disabled}>
            {formatDate(new Date())}
          </MDBox>
          <MDBox sx={{ display: "flex" }}>
            <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{
                    color: text.disabled,
                    cursor: "pointer",
                    fontSize: "1.3em",
                  }}
                >
                  more_vert
                </span>
              }
              sx={{
                right: "18px",
                top: "18px",
              }}
            >
              <TextWithIcon
                onClick={() => {
                  folderService.downloadFile(data.s3_url);
                }}
                iconName={"download"}
                title={"Download File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <Link to={`/folders/${data.folder_id}`}>
                <TextWithIcon
                  onClick={() => {
                    handleClose();
                  }}
                  iconName={"open_in_new"}
                  title={"Show In Folder"}
                  pb={"2px"}
                  sx={{
                    padding: "5px 1rem",
                    transition: "background-color .3s",
                    "&:hover": {
                      backgroundColor: grey[200],
                    },
                  }}
                />
              </Link>
              <TextWithIcon
                onClick={() => {
                  setShowDeleteFileDialog(data);
                }}
                iconName={"delete"}
                title={"Delete File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup>
          </MDBox>
        </MDBox>
      ))}
    </>
  );
};
