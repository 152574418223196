import React, { useEffect, useRef, useState } from "react";
import { Dialog, Typography, Paper } from "@mui/material";
import axios from "axios";
import { useTheme } from "@emotion/react";
import LoadingOverlay from "react-loading-overlay";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import { folderService } from "layouts/folders/service/folderService";

const fileTypes = [
  "application/pdf",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "image/jpeg",
  "image/png",
];

const FileUploadDialog = ({
  open,
  onClose,
  isLoading,
  multiFilesAllowed = false,
  acceptedFileTypes = fileTypes,
  handleUpload,
}) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFiles = (event) => {
    event.preventDefault();
    const newFiles = Array.from(
      event.dataTransfer ? event.dataTransfer.files : event.target.files
    );

    const preFilesNames = files.map((file) => file.name);
    const validFiles = newFiles.filter(
      (file) => !preFilesNames.includes(file.name)
    );

    if (multiFilesAllowed) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    } else {
      setFiles(validFiles);
    }
  };

  const handleDelete = (file) => {
    let filterdFiles = files.filter((data) => data.name !== file.name);
    fileInputRef.current.value = null;
    setFiles(filterdFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text, grey } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiPaper-root": { maxWidth: "40rem" } }}
    >
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", width: "40rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox sx={{ fontSize: "inherit" }}>Upload Files</MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                upload_file
              </span>
            </MDBox>
            <MDBox>
              <MDBox
                onDragOver={handleDragOver}
                onDrop={handleFiles}
                sx={{
                  border: "2px dashed " + primary.main,
                  height: "40vh",
                  borderRadius: "4px",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <MDBox>
                  <MDBox sx={{ fontSize: typography.size["lg"] }}>
                    Drag and drop files here, or click to select files
                  </MDBox>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept={acceptedFileTypes.join(",")}
                    onChange={handleFiles}
                    style={{ display: "none" }}
                    multiple={multiFilesAllowed}
                    id="file-upload-input"
                  />
                  <label
                    htmlFor="file-upload-input"
                    style={{
                      marginTop: "10px",
                      display: "inline-block",
                      backgroundColor: primary.main,
                      color: "white",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Select Files
                  </label>
                </MDBox>
              </MDBox>

              <MDBox
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  gap: "5px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {files.length > 0 && (
                  <>
                    {files.map((file, index) => (
                      // <div key={index}>{file.name}</div>
                      <MDChip
                        key={index}
                        sx={{
                          backgroundColor: grey[200],
                          color: text.disabled,
                          "& svg": {
                            fill: text.disabled,
                          },
                          "&:hover": { backgroundColor: grey[200] },
                        }}
                        icon={
                          <img
                            src={folderService.getFileIcon(file.name)}
                            width={"15px"}
                          />
                        }
                        size={"small"}
                        label={file.name}
                        onDelete={() => handleDelete(file)}
                      />
                    ))}
                  </>
                )}
              </MDBox>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={2}
            >
              <MDChip
                sx={{
                  backgroundColor: error.light + "45",
                  padding: "5px 10px",
                  color: error.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  border: "1px solid " + error.main,
                  width: "140px",
                  "&:hover": {
                    backgroundColor: error.light + "45",
                    boxShadow: "0 0 5px 1px " + error.main + "82",
                  },
                }}
                label={"Cancel"}
                onClick={onClose}
              />
              <MDChip
                sx={{
                  backgroundColor: primary.main,
                  padding: "5px 10px",
                  color: white.main,
                  fontWeight: "bold",
                  cursor: "pointer",
                  width: "140px",
                }}
                label={"Upload"}
                onClick={
                  isLoading || files.length === 0
                    ? () => {}
                    : () => {
                        handleUpload(files);
                      }
                }
              />
            </MDBox>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
};

export default FileUploadDialog;
