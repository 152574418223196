import { Collapse, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
// import "react-medium-image-zoom/dist/styles.css";
import { useTheme } from "@emotion/react";
import { DashboardAPI } from "api";
import CustomDatePicker from "components/controls/CustomDatePicker";
import Dropdown from "components/controls/Dropdown";
import { OutlinedButton } from "components/MDButton/button";
import MDChip from "components/MDChip";
import { format } from "date-fns";
import AnalyticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard/analytics";
import { setGlobalState, useGlobalState } from "Global";
import { convertKeyToName } from "utils/conversion";
import ChartHolder from "./ChartHolder";
import { getFiltersCount } from "layouts/docDashboard/utils";
import zIndex from "@mui/material/styles/zIndex";
import dayjs from "dayjs";
const date = new Date();

export const basicFilterMapping = {
  StartDate: format(new Date(date.getFullYear(), 0, 1), "yyyy-MM-dd"),
  EndDate: format(date, "yyyy-MM-dd"),
};

const ignoreFilterList = [];

export const tileFilterMapping = {
  StartDate: format(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "yyyy-MM-dd"
  ),
  EndDate: format(date, "yyyy-MM-dd"),
};

const dateFilters = ["StartDate", "EndDate"];

const dataMap = {
  OTCBASE: {},
  OTCDSADAKAR: {},
  OTDBASE: {},
  OTDPHC: {},
  VFR: {},
};

function MainDashboard() {
  const [basicFilterOptions, setBasicFilterOptions] = useState({});
  const [basicFilter, setBasicFilter] = useState(basicFilterMapping);
  const [tileFilter, setTileFilter] = useState(tileFilterMapping);
  const [tileFilterCount, setTileFilterCount] = useState(0);
  const [overallData, setOverallData] = useState({
    OTCBASE: [{}],
    OTCDSADAKAR: [{}],
    OTDBASE: [{}],
    OTDPHC: [{}],
    VFR: [{}, {}, {}],
  });
  const [trendsData, setTrendsData] = useState(dataMap);
  const [trendGroupBy, setTrendGroupBy] = useState({
    name: "Weekly trend",
    value: "weekly",
  });
  const [trendGroupByOptions, setTrendGroupByOptions] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isLoadingMap, setIsLoadingMap] = useState({
    OTCBASETile: true,
    OTCDSADAKARTile: true,
    OTDBASETile: true,
    OTDPHCTile: true,
    VFRTile: true,

    OTCBASETrend: true,
    OTCDSADAKARTrend: true,
    OTDBASETrend: true,
    OTDPHCTrend: true,
    VFRTrend: true,
  });
  const [filterNameMapping] = useGlobalState("mapping");

  const getOverallData = (type, newFilter = null) => {
    const dynamicFunction = DashboardAPI[`get${type}DataTile`];
    setIsLoadingMap((prevLoadingMap) => {
      return { ...prevLoadingMap, [type + "Tile"]: true };
    });
    dynamicFunction(
      {
        ...convertToParams(newFilter ? newFilter : tileFilter),
        type: trendGroupBy.value,
      },
      convertToParams(newFilter ? newFilter : tileFilter)
    )
      .then(async (res) => {
        if (res.status == 200) {
          setIsLoadingMap((prevLoadingMap) => {
            return { ...prevLoadingMap, [type + "Tile"]: false };
          });
          setOverallData((prevOverallData) => {
            return { ...prevOverallData, [type]: res.data.tile_data };
          });

          setTileFilterCount(getFiltersCount(tileFilter, ignoreFilterList));
        }
      })
      .catch((error) => {
        setIsLoadingMap((prevLoadingMap) => {
          return { ...prevLoadingMap, [type + "Tile"]: false };
        });
      });
  };

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data["dashboard_common"];
        let filterNamesMapping = {};
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" || filter.type != "Datetime") {
            filterNamesMapping[filter.name] = [];
          }
          filterMapping[filter.name] = filter.value;
        });
        setBasicFilter((prev) => {
          return { ...filterNamesMapping, ...prev };
        });
        setTileFilter((prev) => {
          return { ...filterNamesMapping, ...prev };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });

        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setBasicFilterOptions((prevFilterOptions) => {
                return { ...prevFilterOptions, [filterName.name]: tempData };
              });
            }
          );
        });
      });
  };

  const getGroupByOptions = () => {
    const dynamicFunction = DashboardAPI[`getTrendGroupBy`];
    dynamicFunction().then((res) => {
      if (res.status == 200) {
        setTrendGroupByOptions(res.data);
      }
    });
  };

  const handleTileFilterChange = (event, type = null) => {
    let { name, value } = event.target;

    if (
      (value instanceof Date && !isNaN(value.getTime())) ||
      dayjs.isDayjs(value) ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    }

    let tempTileFilter = {
      ...tileFilter,
      [name]: value,
    };
    setTileFilter(tempTileFilter);
  };

  const resetData = async (type) => {
    if (type == "Tile") {
      setOverallData({
        OTCBASE: [{}],
        OTCDSADAKAR: [{}],
        OTDBASE: [{}],
        OTDPHC: [{}],
        VFR: [{}, {}, {}],
      });
    } else {
      setTrendsData(dataMap);
    }
    return true;
  };

  const getAllOverallData = async () => {
    let temp = await resetData("Tile");
    Object.keys(dataMap).map((infoType) => {
      getOverallData(infoType);
    });
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param]) {
          let tempValue = params[param];
          tempParams[filterNameMapping[param]] = tempValue;
        }
      });
    }
    return tempParams;
  };

  useEffect(() => {
    getBasicFilterValues();
    getGroupByOptions();
    getAllOverallData();
  }, []);

  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const outerContainerStyle = {
    borderRadius: "10px",
    height: "50vh",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  return (
    <MDBox pb={2}>
      {/* <MDBox
        onClick={() => {
          setShowFilters(!showFilters);
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center',
        }}
        pb={1}
      >
        <MDBox sx={{
          fontSize: typography.size["md"]
        }}>
          Delivery Summary
        </MDBox>
        {tileFilterCount !== 0 && (
          <MDBox
            sx={{
              position: "absolute",
              backgroundColor: primary.main,
              top: "-5px",
              right: "-5px",
              width: "20px",
              height: "20px",
              textAlign: "center",
              fontSize: "12px",
              borderRadius: "100px",
            }}
            color={white.main}
          >
            {tileFilterCount}
          </MDBox>
        )}
        <Tooltip title={"Tile Filters"}>
          <OutlinedButton
            sx={{ backgroundColor: showFilters ? "#D6D9FF" : white.main }}
            onClick={() => setShowFilters(!showFilters)}
            name={"Filter"}
            icon={<Icon fontSize="1em">filter_alt</Icon>}
          />
        </Tooltip>
      </MDBox> */}
      {/* <Collapse in={showFilters}>
        <MDBox
          mb={1}
          sx={{
            display: "flex",
            backgroundColor: white.main,
            borderRadius: "10px",
            padding: "1rem",
            boxShadow: "0px 0px 8px 1px #0000000D",
            gap: "1rem",
          }}
        >
          <MDBox
            sx={{
              width: "100%",
              display: "grid",
              columnGap: "1rem",
              gridTemplateColumns: "repeat(4, 1fr)",
              "@media (max-width: 950px)": {
                gridTemplateColumns: "repeat(3, 1fr)",
              },
              "@media (max-width: 800px)": {
                gridTemplateColumns: "repeat(2, 1fr)",
              },
            }}
          >
            {Object.keys(tileFilter).map((filterName, index) => {
              if (!ignoreFilterList.includes(filterName)) {
                if (Array.isArray(tileFilter[filterName])) {
                  return (
                    <MDBox key={index}>
                      {basicFilterOptions[filterName]?.length > 0 && (
                        <Dropdown
                          name={filterName}
                          value={tileFilter[filterName]}
                          onChange={handleTileFilterChange}
                          dataKey={"value"}
                          options={basicFilterOptions[filterName]}
                          placeholder={
                            tileFilter[filterName] != "" ? "" : "Select"
                          }
                          label={convertKeyToName(filterName)}
                        />
                      )}
                    </MDBox>
                  );
                } else if (tileFilter[filterName] != null) {
                  return (
                    <CustomDatePicker
                      name={filterName}
                      label={convertKeyToName(filterName)}
                      value={tileFilter[filterName]}
                      onChange={(e) => {
                        handleTileFilterChange(e, "Date");
                      }}
                    />
                  );
                }
              }
            })}
          </MDBox>

          <MDBox
            sx={{
              width: "30%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <MDBox
              color={primary.main}
              onClick={() => setShowFilters(!showFilters)}
              sx={{
                whiteSpace: "nowrap",
                cursor: "pointer",
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              x Close
            </MDBox>
            <MDChip
              sx={{
                backgroundColor: secondary.main,
                padding: "5px 40px",
                color: white.main,
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "14px",
                height: "40px",
                borderRadius: "100px",
                marginBottom: "10px",
              }}
              label={"Apply All Filters"}
              onClick={() => {
                setShowFilters(!showFilters);
                getAllOverallData();
              }}
            />
          </MDBox>
        </MDBox>
      </Collapse> */}
      {/* <MDBox
        sx={{
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "repeat(7, 1fr)",
          "@media (max-width: 800px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        {overallData &&
          Object.keys(overallData).map((type, index) => {
            return overallData[type].map((card) => {
              return (
                <AnalyticsCard
                  color="dark"
                  icon="equalizer"
                  count={card.value || "0"}
                  title={card.name}
                  width={"100%"}
                  isLoading={isLoadingMap[type + "Tile"]}
                />
              );
            });
          })}
      </MDBox> */}

      {/* Visualisation */}
      <MDBox>
        <MDBox
          sx={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "repeat(2, 1fr)",
            "@media (max-width: 800px)": {
              gridTemplateColumns: "repeat(1, 1fr)",
            },
            "& > :last-child": {
              gridColumn: trendsData.length % 2 === 0 ? "span 1" : "span 2",
            },
          }}
        >
          {trendsData &&
            Object.keys(trendsData).map((type, index) => {
              return (
                <MDBox
                  sx={{
                    ...outerContainerStyle,
                    flex: 1,
                  }}
                >
                  <ChartHolder
                    initialFilters={{
                      ...convertToParams(basicFilter),
                      type: [trendGroupBy.value],
                    }}
                    filterOptions={{
                      ...convertToParams(basicFilterOptions),
                      type: trendGroupByOptions,
                    }}
                    getData={DashboardAPI[`get${type}DataTrend`]}
                    showFilter={true}
                    chartProps={{
                      type: trendsData[type].chart_type,
                      grid: { vertical: false },
                      domain: true,
                    }}
                  ></ChartHolder>
                </MDBox>
              );
            })}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default MainDashboard;
