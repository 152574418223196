export const printHtmlToPdf = (content, userStyles) => {
  const printWindow = window.open("", "_blank");
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        if (styleSheet.href) {
          return `<link rel="stylesheet" type="text/css" href="${styleSheet.href}">`;
        } else {
          return `<style>${styleSheet.ownerNode.innerHTML}</style>`;
        }
      } catch (e) {
        return "";
      }
    })
    .join("");
  printWindow.document.write("<html><head><title>Print</title>");
  printWindow.document.write(styles);
  printWindow.document.write(`<style>${userStyles}</style>`);
  printWindow.document.write("</head><body>");
  printWindow.document.write(content);
  printWindow.document.write("</body></html>");
  printWindow.document.close();
  setTimeout(() => {
    printWindow.print()
  }, 0)
};
