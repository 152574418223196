import { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import LoadingOverlay from "react-loading-overlay";

function FileModel({ file }) {
  const [fileUrl, setFileUrl] = useState(null);

  const getFileType = (fileName) => {
    return fileName.split(".").pop();
  };

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(file.url);

        if (response.ok) {
          const blob = await response.blob();

          let blobWithType = blob;

          if (
            blob.type === "binary/octet-stream" &&
            getFileType(file["name"]) === "pdf"
          ) {
            blobWithType = new Blob([blob], { type: "application/pdf" });
          }

          const objectUrl = URL.createObjectURL(blobWithType);

          setFileUrl(objectUrl);
        } else {
          console.error("Failed to fetch the PDF:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };
    if (file?.rawFile) {
      const fileBlob = new Blob([file.rawFile], { type: file.rawFile.type });
      const objectUrl = URL.createObjectURL(fileBlob);
      setFileUrl(objectUrl);
    } else {
      fetchPdf();
    }

    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [file.url]);

  if (getFileType(file["name"]) === "pdf" && fileUrl) {
    return (
      <iframe
        src={fileUrl}
        width="100%"
        height="100%"
        title="PDF Viewer"
        frameBorder="0"
        style={{ border: "none" }}
      />
    );
  } else if (fileUrl) {
    return (
      <FileViewer fileType={getFileType(file["name"])} filePath={fileUrl} />
    );
  } else {
    return (
      <LoadingOverlay active={true} spinner>
        <style jsx>{`
          ._loading_overlay_wrapper {
            height: 100%;
          }
        `}</style>
      </LoadingOverlay>
    );
  }
}

export default FileModel;

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([new DataView(arrayBuffer)], { type: mimeString });
}
