import TableCell from "./TableCell"

export const applyStyleToEachTableCell = (columnData, data) => {
    const tempData = []
    for(let row of data){
        const tempObject = {}
        for (let column of columnData){
            tempObject[column.accessor] = <TableCell data={row[column.accessor]} type={column.type}/>
        }
        tempData.push(tempObject)
    }
    return tempData
}

export const formatDate = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });
}

export const formatTime = (date) => {
    if (!date.includes('Z') && !date.includes('+')) {
        date += 'Z';
    }
    
    const dateObj = new Date(date);

    return dateObj.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
}