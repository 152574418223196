import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";

const IconCard = ({ iconColor, iconName, title, path, sx }) => {
  const { palette, typography } = useTheme();
  const { white, text } = palette;

  return (
    <Link to={path}>
      <MDBox
        sx={{
          minHeight: "130px",
          height: "100%",
          minWidth: "100px",
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 1px #0000000D",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1rem",
          backgroundColor: white.main,
          ...sx,
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <MDBox
            sx={{
              color: iconColor,
              cursor: "pointer",
              fontSize: "1.5em",
              height: "35px",
              width: "35px",
              display: "grid",
              placeItems: "center",
              position: "relative",
              scale: 1.3,
              "&::after": {
                position: "absolute",
                content: '""',
                height: "35px",
                width: "35px",
                backgroundColor: iconColor,
                borderRadius: ".5em",
                opacity: 0.2,
              },
            }}
          >
            <span class="material-symbols-outlined">{iconName}</span>
          </MDBox>
          <MDBox sx={{ textAlign: "center", fontSize: typography.size["lg"], fontWeight: 'bold' }} color={text.secondary}>
            {title}
          </MDBox>
        </MDBox>
      </MDBox>
    </Link>
  );
};

export default IconCard;
