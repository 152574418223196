import { createGlobalState } from "react-hooks-global-state";

const { setGlobalState, useGlobalState } = createGlobalState({
  error: {
    open: false,
    message: "Error Occured, please contact the admin team!!",
    type: "error",
  },
  userDetails: {},
  basicAnalytics: {},
  advancedAnalytics: {},
  filterOptions:{},
  mapping:{StartDate:"start_timestamp",EndDate:"end_timestamp",limit:"limit",offset:"offset"}
});

export { useGlobalState, setGlobalState };
