import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
// import "react-medium-image-zoom/dist/styles.css";
import { DashboardAPI } from "api";
import { format } from "date-fns";
import { setGlobalState, useGlobalState } from "Global";
import TableDisplay from "examples/Tables/tableDisplay/tableDisplay";
import { useNavigate } from "react-router-dom";
import { convertKeyToName } from "utils/conversion";

const date = new Date();

export const basicFilterMapping = {
  StartDate: format(
    new Date(date.getFullYear(), date.getMonth(), 1),
    "yyyy-MM-dd"
  ),
  EndDate: format(date, "yyyy-MM-dd"),
};

const dateFilters = ["StartDate", "EndDate"];

const tableGroupMap = {
  1: "parent_company_name",
  2: "pride_number",
  3: "weekly",
};

function VFRDashboard() {
  const [basicFilterOptions, setBasicFilterOptions] = useState({});
  const [basicFilterMap, setBasicFilterMap] = useState({
    1: basicFilterMapping,
    2: basicFilterMapping,
    3: basicFilterMapping,
  });

  const [tableDataMap, setTableDataMap] = useState({ 1: {}, 2: {}, 3: {} });
  const [groupByMap, setGroupByMap] = useState(tableGroupMap);
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [isLoadingMap, setIsLoadingMap] = useState({
    1: true,
    2: true,
    3: true,
  });
  const [filterNameMapping] = useGlobalState("mapping");

  const navigation = useNavigate();

  const getVFRData = async (
    index,
    type = "customer_type",
    newFilter = null
  ) => {
    const dynamicFunction = DashboardAPI[`getVFRDataSub`];
    setIsLoadingMap((prevLoadingMap) => {
      return { ...prevLoadingMap, [index]: true };
    });
    let test = await dynamicFunction(
      {
        ...convertToParams(newFilter ? newFilter : basicFilterMap[index]),
        type: type,
      },
      convertToParams(newFilter ? newFilter : basicFilterMap[index])
    )
      .then(async (res) => {
        if (res.status == 200) {
          setIsLoadingMap((prevLoadingMap) => {
            return { ...prevLoadingMap, [index]: false };
          });
          setTableDataMap((prevTableDataMap) => {
            return {
              ...prevTableDataMap,
              [index]: {
                data: res.data.data,
                count: res.data.count,
                pages: res.data.pages,
                totals: res.data.totals,
              },
            };
          });
        }
      })
      .catch((error) => {
        setIsLoadingMap((prevLoadingMap) => {
          return { ...prevLoadingMap, [index]: false };
        });
      });
  };

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data["dashboard_common"];
        let filterNamesMapping = {};
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" || filter.type != "Datetime") {
            filterNamesMapping[filter.name] = [];
          }
          filterMapping[filter.name] = filter.value;
        });
        setBasicFilterMap((prev) => {
          return {
            ...prev,
            1: { ...filterNamesMapping, ...prev[1] },
            2: { ...filterNamesMapping, ...prev[2] },
            3: { ...filterNamesMapping, ...prev[3] },
          };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });
        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setBasicFilterOptions((prevFilterOptions) => {
                return { ...prevFilterOptions, [filterName.name]: tempData };
              });
            }
          );
        });
      });
  };

  const getGroupByOptions = () => {
    const dynamicFunction = DashboardAPI[`getGroupByVFR`];
    dynamicFunction().then((res) => {
      if (res.status == 200) {
        setGroupByOptions(res.data);
      }
    });
  };

  const handleBasicFilterChange = (event, index, type = null) => {
    let { name, value } = event.target;

    if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    } else if (typeof value == typeof [] && value.length > 0) {
      let index = value.length - 1;
      let tempVal = value[index];
      if (tempVal.value == "All") {
        value = [];
      }
    }

    let tempBasicFilter = {
      ...basicFilterMap[index],
      [name]: value,
    };
    setBasicFilterMap((prevBasicFilterMap) => {
      return { ...prevBasicFilterMap, [index]: tempBasicFilter };
    });
    getVFRData(index, groupByMap[index], tempBasicFilter);
  };

  const handleGroupBy = (event, index) => {
    let { name, value } = event.target.value;
    setGroupByMap((prevGroupByMap) => {
      return { ...prevGroupByMap, [index]: value };
    });
    getVFRData(index, value);
  };

  const resetData = async () => {
    setTableDataMap({ 1: {}, 2: {}, 3: {} });
    return true;
  };

  const getAllData = async () => {
    let temp = await resetData();
    getVFRData(1, tableGroupMap[1], basicFilterMap[1]);
  };

  const convertToParams = (params) => {
    let tempParams = {};
    if (params) {
      Object.keys(params).forEach((param) => {
        if (params[param]) {
          let tempValue = params[param];
          if (typeof tempValue == typeof []) {
            tempValue = tempValue.map((tempValue) => {
              return tempValue.value;
            });
          }
          tempParams[filterNameMapping[param]] = tempValue;
        }
      });
    }
    return tempParams;
  };

  useEffect(() => {
    getBasicFilterValues();
    getGroupByOptions();
    getAllData();
  }, []);

  return (
    <>
      {tableDataMap[1] && (
        <MDBox
          mt={2}
          sx={{
            alignItems: "center",
            height: "auto",
            maxWidth: "100%",
          }}
        >
          <TableDisplay
            data={tableDataMap[1].data}
            totals={tableDataMap[1].totals}
            filters={basicFilterMap[1]}
            filterOptions={basicFilterOptions}
            handleFilterChange={(e) => {
              handleBasicFilterChange(e, 1);
            }}
            groupBy={groupByMap[1]}
            groupByOptions={groupByOptions}
            handleGroupChange={(e) => {
              handleGroupBy(e, 1);
            }}
            isLoading={isLoadingMap[1]}
            tableHeight="calc(100vh - 240px)"
          />
        </MDBox>
      )}
    </>
  );
}

export default VFRDashboard;
