import { Checkbox, Icon, Table, TableCell, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import { formatDate } from "examples/Tables/DataTable/utils";
import TextWithIcon from "./TextWithIcon";
import { folderService } from "../service/folderService";
import { useTheme } from "@emotion/react";
import { GridViewSkeleton } from "skeletons/folder/folderSkeleton";

const GridView = ({
  showMultiSelect,
  selectedItems,
  setSelectedItems,
  foldersAndFiles,
  handleSelect,
  handleFolderClick,
  setPreviewFile,
  setShowShareWithDialog,
  setShowDeleteFileDialog,
  setShowDeleteFolderDialog,
  isLoading,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;
  return (
    <MDBox
      mt={2}
      sx={{
        display: "grid",
        gap: "1rem",
        width: "100%",
        gridTemplateColumns: "repeat(4, 1fr)",
        "@media (max-width: 1150px)": {
          gridTemplateColumns: "repeat(3, 1fr)",
        },
        "@media (max-width: 1040px)": {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        "@media (max-width: 550px)": {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
      }}
    >
      {isLoading ? (
        <GridViewSkeleton />
      ) : (
        <>
          {foldersAndFiles?.subfolders?.map((data) => (
            <MDBox
              key={data.folder_id}
              sx={{
                display: "flex",
                backgroundColor: grey[200],
                borderRadius: "10px",
                padding: "10px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MDBox
                color={"inherit"}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                {showMultiSelect && (
                  <Checkbox
                    sx={{
                      backgroundColor: white.main,
                      height: "10px",
                      width: "20px",
                    }}
                    checked={selectedItems.folders.includes(data.folder_id)}
                    onChange={(e, value) => {
                      handleSelect(value, data.folder_id, "folders");
                    }}
                  />
                )}
                <MDBox
                  color={"inherit"}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFolderClick(data.folder_id)}
                >
                  <Icon
                    sx={{
                      fontSize: "1.7em !important",
                      paddingBottom: "5px",
                      color: text.disabled,
                    }}
                  >
                    folder
                  </Icon>
                  <MDBox
                    color={"inherit"}
                    sx={{ width: "100%", overflow: "hidden" }}
                  >
                    <MDBox
                      color={"inherit"}
                      sx={{
                        lineHeight: "1em",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.folder_name}
                    </MDBox>
                    <MDBox
                      color={"inherit"}
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: text.disabled,
                      }}
                    >
                      <MDBox
                        sx={{ fontSize: typography.size["xs"] }}
                        color={"inherit"}
                      >
                        {formatDate(data.created_at)}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox sx={{ display: "flex" }}>
                <QuickActionPopup
                  icon={
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: text.disabled,
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                    >
                      more_vert
                    </span>
                  }
                  sx={{
                    right: "18px",
                    top: "18px",
                  }}
                >
                  {setShowShareWithDialog && (
                    <TextWithIcon
                      onClick={() => {
                        setShowShareWithDialog(data);
                      }}
                      iconName={"group_add"}
                      title={"Share Folder"}
                      pb={"2px"}
                      sx={{
                        padding: "5px 1rem",
                        transition: "background-color .3s",
                        "&:hover": {
                          backgroundColor: grey[200],
                        },
                      }}
                    />
                  )}
                  {setShowDeleteFolderDialog && (
                    <TextWithIcon
                      onClick={() => {
                        setShowDeleteFolderDialog(data);
                      }}
                      iconName={"delete"}
                      title={"Delete Folder"}
                      pb={"2px"}
                      sx={{
                        padding: "5px 1rem",
                        transition: "background-color .3s",
                        "&:hover": {
                          backgroundColor: grey[200],
                        },
                      }}
                    />
                  )}
                </QuickActionPopup>
              </MDBox>
            </MDBox>
          ))}
          {foldersAndFiles?.files?.map((data) => (
            <MDBox
              key={data.file_id}
              sx={{
                display: "flex",
                backgroundColor: grey[200],
                borderRadius: "10px",
                padding: "10px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <MDBox
                color={"inherit"}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "200px",
                }}
              >
                {showMultiSelect && (
                  <Checkbox
                    sx={{
                      backgroundColor: white.main,
                      height: "10px",
                      width: "20px",
                    }}
                    checked={selectedItems.files.includes(data.file_id)}
                    onChange={(e, value) => {
                      handleSelect(value, data.file_id, "files");
                    }}
                  />
                )}
                <MDBox
                  color={"inherit"}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setPreviewFile({
                      name: data.filename,
                      url: data.s3_url,
                      id: data.file_id,
                      rawFile: data?.rawFile,
                    })
                  }
                >
                  <img
                    src={folderService.getFileIcon(data.filename)}
                    style={{ height: "2em" }}
                  />
                  <MDBox
                    color={"inherit"}
                    sx={{ width: "100%", overflow: "hidden" }}
                  >
                    <MDBox
                      color={"inherit"}
                      sx={{
                        lineHeight: "1em",
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.filename}
                    </MDBox>
                    <MDBox
                      color={"inherit"}
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        color: text.disabled,
                      }}
                    >
                      <MDBox
                        sx={{ fontSize: typography.size["xs"] }}
                        color={"inherit"}
                      >
                        {formatDate(data.created_at)}
                      </MDBox>
                      .
                      <MDBox
                        sx={{ fontSize: typography.size["xs"] }}
                        color={"inherit"}
                      >
                        {folderService.convertBytes(data.size_in_bytes || 0)}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox sx={{ display: "flex" }}>
                <QuickActionPopup
                  icon={
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: text.disabled,
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                    >
                      more_vert
                    </span>
                  }
                  sx={{
                    right: "18px",
                    top: "18px",
                  }}
                >
                  <TextWithIcon
                    onClick={() => {
                      folderService.downloadFile(data.s3_url);
                    }}
                    iconName={"download"}
                    title={"Download File"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  />
                  {/* {setShowShareWithDialog && (
                    <TextWithIcon
                      onClick={() => {
                        setShowShareWithDialog(data);
                      }}
                      iconName={"group_add"}
                      title={"Share File"}
                      pb={"2px"}
                      sx={{
                        padding: "5px 1rem",
                        transition: "background-color .3s",
                        "&:hover": {
                          backgroundColor: grey[200],
                        },
                      }}
                    />
                  )} */}
                  {setShowDeleteFileDialog && (
                    <TextWithIcon
                      onClick={() => {
                        setShowDeleteFileDialog(data);
                      }}
                      iconName={"delete"}
                      title={"Delete File"}
                      pb={"2px"}
                      sx={{
                        padding: "5px 1rem",
                        transition: "background-color .3s",
                        "&:hover": {
                          backgroundColor: grey[200],
                        },
                      }}
                    />
                  )}
                </QuickActionPopup>
              </MDBox>
            </MDBox>
          ))}
        </>
      )}
    </MDBox>
  );
};

export default GridView;
