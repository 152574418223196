// @mui material components

//  React components
import MDBox from "components/MDBox";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DataTable from "examples/Tables/DataTable";
// Data
import { setGlobalState } from "Global";
import { ExcelDataAPI } from "api";

import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router-dom";
//  React example components
// Data

// Dashboard components
import { useTheme } from "@emotion/react";
import { useGlobalState } from "Global";
import { DashboardAPI } from "api";
import { format } from "date-fns";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import useExcelData from "./data/excelData";
import dayjs from "dayjs";

function Tables(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState([]);

  const [selectedReference, setSelectedReference] =
    useState("delivery_otd_base");

  const [referenceList, setReferenceList] = useState([]);
  const [tempFilters, setTempFilters] = useState({})
  const [filters, setFilters] = useState({
    delivery_otc: {
      limit: 50,
      offset: 0,
    },
    delivery_otd: {
      limit: 50,
      offset: 0,
    },
    zcvr: {
      limit: 50,
      offset: 0,
    },
    daily_picking_tracker: {
      limit: 50,
      offset: 0,
    },
    customer_mapping: {
      limit: 50,
      offset: 0,
    },
  });

  useEffect(() => {
    setTempFilters(filters)
  }, [filters])
  const handleClickApplyFilter = () => {
    setFilters(tempFilters)
  }

  const [filterOptions, setFilterOptions] = useState({});
  const [filterTypeMapping, setFilterTypeMapping] = useState({});
  const navigation = useNavigate();

  const handleDeleteRow = (reference, id) => {
    ExcelDataAPI.deleteMainRecord({ reference: reference, id: id })
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: `Deleted the record with ID -${id} successfully`,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const [filterMapping] = useGlobalState("mapping");
  const [globalFilterOptions] = useGlobalState("filterOptions");

  const [rows, columns, pageInfo, onClick, updatedRows] = useExcelData({
    setIsLoading: setIsLoading,
    reference: selectedReference,
    dataAPI: "Main",
    filters: filters[selectedReference],
    handleDeleteRow: handleDeleteRow,
    filterTypeMapping: filterTypeMapping,
    filterMapping: filterMapping,
  });

  const getBasicFilterValues = () => {
    let filterNames = {};
    DashboardAPI.getFilterNames()
      .then((res) => {
        filterNames = res.data[`${selectedReference}_main`];
        let tempFilterTypeMapping = {};
        let filterNamesMap = { limit: 50, offset: 0 };
        let filterMapping = {};
        filterNames.map((filter) => {
          if (filter.type != "Date" && filter.type != "Datetime") {
            filterNamesMap[filter.name] = [];
          } else {
            filterNamesMap[filter.name] = "";
          }
          tempFilterTypeMapping[filter.name] = filter.type;
          filterMapping[filter.name] = filter.value;
        });
        setFilters((prevFilters) => {
          return { ...prevFilters, [selectedReference]: filterNamesMap };
        });
        setFilterTypeMapping((prevFilterTypeMapping) => {
          return { ...prevFilterTypeMapping, ...tempFilterTypeMapping };
        });
        setGlobalState("mapping", (prevState) => {
          return { ...prevState, ...filterMapping };
        });

        return filterNames;
      })
      .then((filterNames) => {
        filterNames.map((filterName) => {
          DashboardAPI.getFilterValues({ filter_name: filterName.value }).then(
            (res) => {
              let tempData = res.data;
              tempData.push({ name: "All", value: "All" });
              setFilterOptions((prevFilterOptions) => {
                let newFilterOptions = {
                  ...prevFilterOptions,
                  [filterName.name]: tempData,
                };
                return newFilterOptions;
              });
              setGlobalState("filterOptions", (prevFilterOptions) => {
                return {
                  ...prevFilterOptions,
                  [selectedReference]: {
                    ...prevFilterOptions[selectedReference],
                    [filterName.name]: tempData,
                  },
                };
              });
            }
          );
        });
      });
  };

  const updateRecords = () => {
    ExcelDataAPI.updateMainRecords(
      { reference: selectedReference },
      Object.values(updatedRows)
    )
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: `Successfully updated the records`,
            type: "success",
          });
          setFilters((prevFilters) => {
            return {
              ...prevFilters,
              [selectedReference]: {
                ...prevFilters[selectedReference],
                [new Date().toDateString()]: "1",
              },
            };
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getReferenceList = () => {
    ExcelDataAPI.getReferences()
      .then((res) => {
        if (res.status == 200) {
          setReferenceList(res.data);
          setSelectedReference(res.data[0].value);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getReferenceList();
  }, []);

  useEffect(() => {
    if (!globalFilterOptions[selectedReference]) {
      getBasicFilterValues();
    } 
  }, [selectedReference]);

  const handleFilterChange = (event) => {
    console.log([event.target.name],event.target.value,selectedReference)
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : value;
      }
    }
  
    if (
      (event.target.value instanceof Date && !isNaN(event.target.value.getTime())) || dayjs.isDayjs(event.target.value)
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd 00:00:00");
    } else if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = {
      ...filters,
      [selectedReference]: {
        ...filters[selectedReference],
        [event.target.name]: event.target.value,
      },
    };
    // If limit is changes reset offset to 0
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    }
    console.log(tempFilters)
    setTempFilters(tempFilters);
  };

  const capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              table_view
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Deliveries</MDBox>
        }
      />
      <MDBox
        sx={{
          position: "relative",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            fontSize: typography.size["sm"],
            fontWeight: typography.fontWeightMedium,
            borderBottom: `1px solid ${palette.grey[300]}`,
            gap: "1em",
          }}
          mb={2}
        >
          {referenceList.map((tab, index) => (
            <MDBox
              key={index}
              sx={{
                padding: pxToRem(8),
                cursor: "pointer",
                gap: "5px",
                display: "flex",
                paddingBottom: pxToRem(5),
                paddingTop: 0,
                borderBottom:
                  selectedReference === tab?.value
                    ? `2px solid ${palette.primary.main}`
                    : ``,
              }}
              color={palette.text.header}
              onClick={() => setSelectedReference(tab?.value)}
            >
              {tab?.name}
            </MDBox>
          ))}
        </MDBox>
        <DataTable
          table={{
            columns: columns,
            rows: rows,
          }}
          isSorted={true}
          canSearch={true}
          showTotalEntries={true}
          noEndBorder
          filters={
            tempFilters[selectedReference] == null ? [] : tempFilters[selectedReference]
          }
          handleClickApplyFilter={handleClickApplyFilter}
          filterOptions={filterOptions}
          handleFilterChange={handleFilterChange}
          customPagination={true}
          ignoreFilterList={["limit", "offset"]}
          pageInfo={pageInfo}
          isLoading={isLoading}
          rowClick={onClick}
          updateButton={
            Object.keys(updatedRows).length > 0 ? updateRecords : null
          }
          tableHeight="calc(100vh - 231px)"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
