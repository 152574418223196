import { useTheme } from "@emotion/react";
import { CircularProgress } from "@mui/material";
import { deliveryAPI } from "api";
import Controls from "components/controls/Controls";
import MDBox from "components/MDBox";
import MDChip from "components/MDChip";
import { format } from "date-fns";
import dayjs from "dayjs";
import { setGlobalState } from "Global";
import { deliveryService } from "layouts/delivery/service/deliveryService";
import { useEffect, useState } from "react";

const DeliveryOverview = ({
  deliveryId,
  deliveryOverview,
  setDeliveryOverview,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [deliveryOverviewMap, setDeliveryOverviewMap] = useState({});

  useEffect(() => {
    let tempData = {};
    deliveryOverview.forEach((data) => {
      tempData[data.field] = data.value;
    });
    setDeliveryOverviewMap(tempData);
  }, [deliveryOverview]);

  const getOverViewData = () => {
    setIsLoading(true);
    deliveryAPI
      .getDeliveryDetails(deliveryId)
      .then((res) => {
        if (res.status === 200) {
          setDeliveryOverview(res.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    deliveryAPI
      .updateDeliveryDetails(deliveryId, deliveryOverviewMap)
      .then((res) => {
        if (res.status === 200) {
          setGlobalState("error", {
            open: true,
            message: "Data Updated successfully!",
            type: "success",
            code: 200,
          });
          let tempValue = [];
          deliveryOverview.forEach((data) => {
            tempValue.push({
              ...data,
              value: deliveryOverviewMap[data.field],
            });
          });
          console.log(tempValue);
          setDeliveryOverview(tempValue);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (event, type) => {
    if (
      (event.target.value instanceof Date &&
        !isNaN(event.target.value.getTime())) ||
      dayjs.isDayjs(event.target.value) ||
      type == "Date"
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd");
    }

    let tempdata = {
      ...deliveryOverviewMap,
      [event.target.name]: event.target.value,
    };
    setDeliveryOverviewMap(tempdata);
  };

  useEffect(() => {
    if (deliveryOverview?.length === 0) {
      getOverViewData();
    }
  }, [deliveryId]);

  return (
    <MDBox sx={{ padding: "10px 1rem", position: "relative", height: "100%" }}>
      {isLoading ? (
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            color="primary"
            sx={{ height: "3rem !important", width: "3rem !important" }}
          />
        </MDBox>
      ) : (
        <MDBox
          sx={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "40% calc(60% - 1.5rem)",
            columnGap: "1.5rem",
            "@media (max-width: 900px)": {
              gridTemplateColumns: "100%",
            },
          }}
        >
          {deliveryOverviewMap &&
          Object.keys(deliveryOverviewMap).length > 0 ? (
            deliveryOverview.map((data) => {
              let ControlType = data.type && Controls[data.type]
                ? Controls[data.type]
                : Controls["Text"];
              return (
                <ControlType
                  name={data.field}
                  label={data.name}
                  value={deliveryOverviewMap[data.field] || ""}
                  onChange={(e) => handleChange(e)}
                  options={data.options}
                  disabled={!data.edit}
                />
              );
            })
          ) : (
            <></>
          )}
        </MDBox>
      )}

      <MDChip
        sx={{
          backgroundColor: primary.main,
          padding: "5px 10px",
          color: white.main,
          fontWeight: "bold",
          cursor: "pointer",
          marginTop: "10px",
          position: "sticky",
          float: "right",
          right: 0,
          bottom: "0",
        }}
        label={"Update"}
        onClick={() => handleUpdate()}
      />
    </MDBox>
  );
};

export default DeliveryOverview;
