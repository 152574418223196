import { useEffect, useState } from "react";
import { deliveryService } from "../service/deliveryService";

const initialFilters = {
  search: "",
  page_no: 1,
  limit: 10,
  offset: 0,
};

const useDelivery = ({ applyFilterFlag }) => {
  const [filters, setFilters] = useState(initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [filterOptions, setFilterOptions] = useState([])
  const [columnData, setColumnData] = useState([])

  useEffect(()=> {
    setIsLoading(true);
    deliveryService
      .getDeliveryFilterOptions()
      .then((res) => {
        if (res.status === 200) {
          const options = []
          const tempFilters = []
          res.data.forEach(data => {
            options[data.field] = data.options || ""
            tempFilters[data.field] = data.options ? [] : ""
          });
          setFilterOptions(options);
          setFilters(pre => ({...pre, ...tempFilters}))
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [])

  useEffect(() => {
    setIsLoading(true);
    const {page_no, limit, search, offset, ...remainingfilters} = filters
    deliveryService
      .getDeliveryData({filters : remainingfilters, page_no: filters.offset + 1, limit, search})
      .then((res) => {
        if (res.status === 200) {
          setPageInfo({ count: res.data.total_count, pages: Math.ceil(res.data.total_count / filters.limit) });
          setRows(res.data.data);
          setColumnData(res.data.columns)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [applyFilterFlag]);

  return {
    isLoading,
    filterOptions,
    deliveries: rows,
    columns: columnData,
    pages: pageInfo,
    filters, setFilters,
  };
};

export default useDelivery;
