import { integrationAPI } from "api";

export const emailService = {
  getAllEmails(data) {
    return integrationAPI.getAllEmails(data);
  },
  getAttachmentDetails(id) {
    return integrationAPI.getAttachmentDetails(id);
  },
};
