import styled from "@emotion/styled";
import { TableContainer } from "@mui/material";

export const MDTableContainer = styled(TableContainer)(({ theme, color }) => ({
  boxShadow: 'none',
  borderRadius: '0',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '10px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#00000000',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: color,
    borderRadius: '50px',
  },
  
  '&::-webkit-scrollbar-track-horizontal': {
    backgroundColor: '#00000000',
  },
}));
