import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDChip
import MDChipRoot from "components/MDChip/MDChipRoot";

import { useMaterialUIController } from "context";

const MDChip = forwardRef(
  ({ color, variant, size, icon, avatar, onDelete, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <MDChipRoot
        {...rest}
        ref={ref}
        color={color}
        avatar={avatar}
        icon={icon}
        onDelete={onDelete}
        variant={variant === "outlined" ? "outlined" : variant}
        size={size}
        ownerState={{ color, variant, size, icon, avatar, onDelete, darkMode }}
      >
      </MDChipRoot>
    );
  }
);

// Setting default values for the props of MDChip
MDChip.defaultProps = {
  size: "medium",
  variant: "filled",
  color: "primary",
  avatar: false,
  icon: false,
  onDelete:false
};

// Typechecking props for the MDChip
MDChip.propTypes = {
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["filled", "outlined"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
};

export default MDChip;
