import MDBox from "components/MDBox";
import aiLoading from "assets/images/icons/ai-loading.svg";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

const AiLoading = ({ messages = ["Loading..."], time = 500 }) => {
  const theme = useTheme();

  const [activeMessage, setActiveMessage] = useState(messages[0]);
  const count = useRef(1);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count.current < messages.length) {
        setActiveMessage(messages[count.current]);
        count.current++;
      } else {
        clearInterval(intervalId);
      }
    }, time);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <MDBox
      sx={{
        display: "flex",
        alignItems: "center",
        gap: '3px'
      }}
    >
      <img
        className="rotate-animation"
        src={aiLoading}
        style={{
          height: '2em'
        }}
      />
      <MDBox
        sx={{
          background: `-webkit-linear-gradient(45deg, ${theme.palette.primary.main} 70%, ${theme.palette.secondary.main})`,
          backgroundClip: "text",
          textFillColor: "transparent",
          fontWeight: "500",
          marginTop: '3px'
        }}
      >
        {activeMessage}
      </MDBox>
    </MDBox>
  );
};

export default AiLoading;
