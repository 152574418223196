import { Dialog } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { useEffect } from "react";

import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import Controls from "components/controls/Controls";
import MDChip from "components/MDChip";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { convertKeyToName } from "utils/conversion";

function ShareDialog({
  open,
  handleClose,
  defaultInputs,
  handleSubmit,
  buttonName = "Add buttonName",
  heading = "Add heading",
  iconName = "Add google iconName",
  isLoading,
  doubleColAfterInputs = 3,
  topInfo,
  shareWith,
}) {
  const [state, setState] = useState({});
  const [removedList, setRemovedList] = useState([]);

  const handleOnRemovedClick = (userId) => {
    setRemovedList(removedList.filter((id) => id !== userId));
    setState({
      ...state,
      ["shared_with"]: {
        ...state["shared_with"],
        value: [...state["shared_with"].value, userId],
      },
    });
  };

  const handleSubmitClick = () => {
    let isValid = true;
    let temp = {};
    let tempdata = {};
    Object.keys(state).forEach((key) => {
      temp[key] = state[key];
      if (state[key].required && !state[key].value) {
        state[key].error = true;
        isValid = false;
      } else {
        tempdata[key] = state[key].value;
        state[key].error = false;
      }
    });
    if (isValid) {
      handleSubmit(tempdata, removedList);
    } else {
      setState(temp);
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "shared_with" && shareWith) {
      const shareWithSet = new Set(event.target.value);
      const removedList = shareWith.filter((item) => !shareWithSet.has(item));
      setRemovedList(removedList);
    }
    setState({
      ...state,
      [event.target.name]: {
        ...state[event.target.name],
        value: event.target.value,
        error:
          state[event.target.name]?.required && !event.target.value
            ? true
            : false,
      },
    });
  };

  const initializeData = () => {
    let tempState = {};
    Object.keys(defaultInputs).map((feature) => {
      tempState[feature] = defaultInputs[feature];
    });
    setState(tempState);
  };

  useEffect(() => {
    if (defaultInputs) {
      initializeData();
    }
  }, [defaultInputs]);

  const { palette, functions, typography } = useTheme();
  const { primary, white, error, warning, text, grey } = palette;
  const { pxToRem } = functions;

  return (
    <Dialog open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{ marginRight: "0%", marginLeft: "0%", width: "29rem" }}
      >
        <LoadingOverlay active={isLoading} spinner>
          <MDBox p={3}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: typography.size["xl"],
                fontWeight: typography.fontWeightBold,
              }}
              pb={2}
            >
              <MDBox
                sx={{
                  fontSize: "inherit",
                  width: "25rem",
                  overflow: "hidden",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {heading}
              </MDBox>
              <span
                class="material-symbols-outlined"
                style={{ color: primary.main }}
              >
                {iconName}
              </span>
            </MDBox>
            {topInfo && (
              <MDBox
                mb={1}
                sx={{ fontSize: typography.size["xs"] }}
                color={text.disabled}
              >
                {topInfo}
              </MDBox>
            )}
            <Grid container columnSpacing={2}>
              {Object.keys(state).map((feature, index) => {
                let ControlType = Controls[state[feature]?.type];
                return (
                  <Grid
                    item
                    xs={index < doubleColAfterInputs ? 12 : 6}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <MDBox sx={{ width: "100%" }}>
                      <ControlType
                        name={feature || ""}
                        variant={
                          index < doubleColAfterInputs ? "standard" : "outlined"
                        }
                        label={
                          state[feature]?.label || convertKeyToName(feature)
                        }
                        value={state[feature]?.value}
                        dataKey={state[feature]?.dataKey}
                        labelKey={state[feature]?.labelKey}
                        onChange={handleChange}
                        disabled={!state[feature]?.editable}
                        options={state[feature]?.options || []}
                        error={state[feature]?.error}
                        helperText={
                          state[feature]?.error
                            ? "This field is required."
                            : null
                        }
                      />
                    </MDBox>
                  </Grid>
                );
              })}
              <Grid item xs={2} md={12}>
                {removedList && removedList.length > 0 && (
                  <MDBox>
                    <MDBox color={text.disabled}>Removed users:</MDBox>
                    <MDBox
                      mt={1}
                      sx={{
                        display: "flex",
                        gap: "5px",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {removedList.map((user, index) => (
                        <MDChip
                          key={index}
                          sx={{
                            backgroundColor: grey[200],
                            color: text.disabled,
                            "& svg": {
                              fill: text.disabled,
                            },
                            "&:hover": { backgroundColor: grey[200] },
                          }}
                          size={"small"}
                          label={user}
                          onDelete={() => handleOnRemovedClick(user)}
                        />
                      ))}
                    </MDBox>
                  </MDBox>
                )}
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                >
                  <MDChip
                    sx={{
                      backgroundColor: error.light + "45",
                      padding: "5px 10px",
                      color: error.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      border: "1px solid " + error.main,
                      width: "140px",
                      "&:hover": {
                        backgroundColor: error.light + "45",
                        boxShadow: "0 0 5px 1px " + error.main + "82",
                      },
                    }}
                    label={"Cancel"}
                    onClick={handleClose}
                  />
                  <MDChip
                    sx={{
                      backgroundColor: primary.main,
                      padding: "5px 10px",
                      color: white.main,
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "140px",
                    }}
                    label={buttonName}
                    onClick={() => handleSubmitClick()}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </LoadingOverlay>
      </Paper>
    </Dialog>
  );
}

export default ShareDialog;
