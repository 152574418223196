import { useTheme } from "@emotion/react";
import { Grow } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useRef, useState } from "react";

const QuickActionPopup = ({ icon, children, sx }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popRef = useRef(null);
  const { palette } = useTheme();
  const { primary, white } = palette;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showPopup &&
        popRef.current &&
        !popRef.current.contains(event.target)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <MDBox ref={popRef} sx={{ position: "relative", display: "inline-block" }}>
      <MDBox
        onClick={() => setShowPopup((pre) => !pre)}
        sx={{ position: "relative", display: "flex" }}
      >
        {icon}
      </MDBox>
      <Grow in={showPopup}>
        <MDBox
          py={1}
          sx={{
            position: "absolute",
            right: "-24px",
            top: "45px",
            minWidth: "100px",
            minHeight: "50px",
            maxHeight: "400px",
            borderRadius: "10px",
            backgroundColor: white.main,
            boxShadow: "0px 4px 8px 2px #0000001a",
            overflowY: "auto",
            zIndex: 2,
            ...sx,
          }}
        >
          {children}
        </MDBox>
      </Grow>
    </MDBox>
  );
};

export default QuickActionPopup;
