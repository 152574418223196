import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useGlobalState, setGlobalState } from "Global";
import { useEffect } from "react";

export default function CustomAlert() {
  const [error] = useGlobalState("error");
  
  const setOpen = () => {
    setGlobalState("error", { ...error, open: false });
  };

  useEffect(() => {
    if (error.open == true) {
      setTimeout(setOpen, 6000);
    }
  }, [error.open]);

  return (
    <Collapse
      in={error.open}
      sx={{
        position: "fixed",
        top: "2rem",
        left: "5%",
        right: "5%",
        width: "90%",
        zIndex: "100000",
      }}
    >
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen();
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={error.type}
        sx={{ mb: 2 }}
      >
        {(typeof(error.message) === "object")? error.message?.detail : error.message}
      </Alert>
    </Collapse>
  );
}
