import { useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import { Popover, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import {
  setMiniSidenav,
  setOpenConfigurator,
  useMaterialUIController,
} from "context";
// import NotificationList from "layouts/profile2/notificationList";
import InitialsAvatar from "utils/initialsAvatar";
import { useGlobalState } from "Global";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState("sticky");
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const user = useGlobalState("userDetails");

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  // const renderMenu = () => (
  //   <Popover
  //     anchorEl={openMenu}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "right",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //   >
  //     <NotificationList />
  //   </Popover>
  // );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    fontSize: "1rem !important",
  });

  return (
    <AppBar
      position={"sticky"}
      color="inherit"
      sx={(theme) => navbar(theme, { light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={{ fontSize: "1rem !important", marginLeft: "0.5rem" }}
          className={"font-unset"}
        >
          {/* <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} /> */}
          {route[route.length - 1].toUpperCase()}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <CustomInput size={"small"} name={"search"}  label="Search..." />
            </MDBox> */}
            <MDBox sx={{ display: "flex", flexDirection: "row" }}>
              <MDTypography
                sx={{
                  alignSelf: "center",
                  fontSize: "medium !important",
                  fontWeight: "500",
                  marginRight: "0.5rem",
                }}
                className={"font-unset"}
              >
                {user.full_name}
              </MDTypography>
              <Tooltip title={"Profile"}>
                <Link
                  to="/profile"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <MDBox>
                    <InitialsAvatar
                      name={user.full_name}
                      sx={{ fontSize: "0.7rem !important", padding: "0.5rem" }}
                      fontSize="small !important"
                      className={"font-unset"}
                    />
                  </MDBox>
                </Link>
              </Tooltip>

              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}

              <Tooltip title={"Notifications"}>
                <IconButton
                  disableRipple
                  color="inherit"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  className={"font-unset"}
                >
                  <Icon sx={{ fontSize: "1.5rem !important" }}>
                    notifications
                  </Icon>
                </IconButton>
              </Tooltip>
              {/* {renderMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
