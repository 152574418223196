// @mui material components

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { setGlobalState } from "Global";
import { Link, useNavigate } from "react-router-dom";
// Data

// Dashboard components
import { Switch, Tab, Tabs } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";

import AccessWrapper from "wrappers/accessWrapper";

import { useTheme } from "@emotion/react";
import { ExcelDataAPI } from "api";
import MDChip from "components/MDChip";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import useExcelData from "./ExcelData";

function Bulk(props) {
  const navigation = useNavigate();

  const [tabValue, setTabValue] = useState("0");
  const [tableValue, setTableValue] = useState("Success");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [isLoadingFail, setIsLoadingFail] = useState(false);
  const [excelData, setExcelData] = useState({});
  const [selectedReference, setSelectedReference] =
    useState("delivery_otd_base");
  const [expectedColumns, setExpectedColumns] = useState({});
  const [referenceList, setReferenceList] = useState([]);
  const [filters, setFilters] = useState({
    limit: 50,
    offset: 0,
  });
  const [updateFlag, setUpdateFlag] = useState(false);

  const [successRows, successColumns, successPageInfo] = useExcelData({
    setIsLoading: setIsLoadingSuccess,
    reference: selectedReference,
    dataAPI: "Success",
    filters: filters,
  });

  const [failRows, failColumns, FailPageInfo] = useExcelData({
    setIsLoading: setIsLoadingFail,
    reference: selectedReference,
    dataAPI: "Fail",
    filters: filters,
  });

  useEffect(() => {
    setExcelData({
      Success: { rows: successRows, columns: successColumns },
      Fail: { rows: failRows, columns: failColumns },
    });
  }, [successRows, failRows, failColumns, successColumns]);

  const handleFilterChange = (event) => {
    let tempFilters = { ...filters, [event.target.name]: event.target.value };
    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    }
    setFilters(tempFilters);
  };

  const convertKeyToName = (key) => {
    let name = key
      .split("_")
      .map((word) => {
        return word[0].toUpperCase() + word.slice(1);
      })
      .join(" ");
    return name;
  };

  const handleExcelFileChange = async (event) => {
    setIsLoading(true);
    let tempSelectedReference = selectedReference;
    const formdata = new FormData();
    formdata.append(event.target.name, event.target.files[0]);
    setSelectedReference(null);
    ExcelDataAPI.excelUpload(
      { reference: selectedReference, update: updateFlag },
      formdata
    )
      .then((res) => {
        if (res.status == 200) {
          setGlobalState("error", {
            open: true,
            message: "Successfully uploaded the File",
            type: "success",
          });
          setSelectedReference(tempSelectedReference);
          // Get Success and Fail Tables
          setIsLoading(false);
          event.target.value = null;
        }
      })
      .catch((error) => {
        event.target.value = null;
        setSelectedReference(tempSelectedReference);
        setIsLoading(false);
      });
  };

  const getReferenceList = () => {
    ExcelDataAPI.getReferences()
      .then((res) => {
        if (res.status == 200) {
          setReferenceList(res.data);
          setSelectedReference(res.data[0].value);
          setIsLoading(false);
          let tempColumns = {};
          res.data.map((reference) => {
            tempColumns[reference.value] = reference.columns_expected;
          });
          setExpectedColumns(tempColumns);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getReferenceList();
  }, []);

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              upload
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/integrations"}>
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            </Link>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "1.5em" }}
            >
              chevron_right
            </span>
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
              File Upload
            </MDBox>
          </MDBox>
        }
        rightContent={
          <Tabs
            sx={{
              background: primary.light,
              "& .MuiTab-root": {
                fontSize: typography.size["sm"] + " !important",
                color: text.disabled + " !important",
                width: "100px",
              },
              "& .Mui-selected": {
                fontSize: typography.size["sm"],
                color: primary.main + " !important",
                outline: "none",
              },
            }}
            orientation={"horizontal"}
            value={tableValue}
            onChange={(event, value) => setTableValue(value)}
          >
            <Tab label="Success" value="Success" />
            <Tab label="Failure" value="Fail" />
          </Tabs>
        }
      />
      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          borderBottom: `1px solid ${palette.grey[300]}`,
          gap: "1em",
        }}
        mb={2}
      >
        {referenceList.map((tab, index) => (
          <MDBox
            key={index}
            sx={{
              padding: pxToRem(8),
              cursor: "pointer",
              gap: "5px",
              display: "flex",
              paddingBottom: pxToRem(5),
              paddingTop: 0,
              borderBottom:
                selectedReference === tab?.value
                  ? `2px solid ${palette.primary.main}`
                  : ``,
            }}
            color={palette.text.header}
            onClick={() => setSelectedReference(tab?.value)}
          >
            {tab?.name}
          </MDBox>
        ))}
      </MDBox>
      <MDBox>
        <MDBox
          sx={{
            alignItems: "center",
            height: "auto",
            maxWidth: "100%",
            marginBottom: "1rem",
            minHeight: "15vh",
          }}
        >
          <MDBox
            sx={{
              position: "relative",
            }}
          >
            {excelData[tableValue] && (
              <DataTable
                table={{
                  columns: excelData[tableValue].columns,
                  rows: excelData[tableValue].rows,
                }}
                isLoading={
                  tableValue == "Success" ? isLoadingSuccess : isLoadingFail
                }
                isSorted={true}
                canSearch={true}
                showTotalEntries={true}
                noEndBorder
                customPagination={true}
                ignoreFilterList={["limit", "offset"]}
                pageInfo={
                  tableValue == "Success" ? successPageInfo : FailPageInfo
                }
                tableHeight="calc(100vh - 225px)"
              />
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox my={2}>
        <b>Required Columns in Excel Upload:</b>
        <MDBox sx={{ display: "flex", marginBottom: "0.5rem" }}>
          {expectedColumns[selectedReference] &&
            expectedColumns[selectedReference].map((column) => {
              return <>{column}, &nbsp;</>;
            })}
        </MDBox>
      </MDBox>
      <MDBox
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: white.main,
          borderRadius: "10px",
          boxShadow: "0px 0px 8px 1px #0000000D",
        }}
      >
        <MDTypography variant="h6" fontWeight="medium">
          {selectedReference && convertKeyToName(selectedReference)} File Upload
        </MDTypography>
        <MDBox
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <MDBox sx={{ color: text.disabled, fontWeight: "500" }}>
              Enable Bulk Update
            </MDBox>
            <Switch
              checked={updateFlag}
              onChange={(e, value) => setUpdateFlag(value)}
              sx={{
                "& .Mui-checked .MuiSwitch-thumb": {
                  borderColor: primary.main + " !important",
                },
                "& .Mui-checked+.MuiSwitch-track": {
                  borderColor: primary.main + " !important",
                  backgroundColor: primary.main + " !important",
                },
              }}
            />
          </MDBox>
          <label htmlFor="file">
            <input
              type="file"
              onChange={(e) => handleExcelFileChange(e)}
              name="file"
              id="file"
              style={{ display: "none" }}
            />
            <MDChip
              sx={{
                paddingX: "1rem",
                fontWeight: "bold",
                fontSize: typography.size["xs"],
                color: primary.main,
                border: "1px solid " + primary.main,
                backgroundColor: primary.light,
                "&:hover": {
                  backgroundColor: primary.light,
                  boxShadow: "0 0 5px 1px " + primary.main + "82",
                },
                cursor: "pointer",
              }}
              label={"Upload Document"}
            />
          </label>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default AccessWrapper(Bulk, ["agent", "superuser", "admin"]);
