import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { useState } from "react";
import { Link } from "react-router-dom";
import useEmail from "./hooks/useEmail";
import DataTable from "examples/Tables/DataTable";
import EmailViewer from "./EmailViewer";
import DialogWrapper from "utils/dialog/dialogWrapper";
import FileModel from "layouts/folders/components/FileViewer";
import dayjs from "dayjs";
import { format } from "date-fns";

const initialFilters = {
  page_no: 1,
  offset: 0,
  limit: 10,
  from_date: "",
  to_date: "",
};
const ignoreFilterList = ["limit"];

const Email = () => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [filters, setFilters] = useState(initialFilters);
  const [applyFilterFlag, setApplyFilterFlag] = useState(false);
  const [activeEmail, setActiveEmail] = useState(null);

  const { isLoading, emailDetails, columns, pages } = useEmail({
    filters,
    applyFilterFlag,
  });

  const handleRowClick = (row) => {
    const clickedData = {};
    Object.keys(row.values).forEach((key) => {
      clickedData[key] = row.values[key].props.data;
    });
    setActiveEmail(clickedData);
  };

  const clearAllFilter = () => {
    setFilters(initialFilters)
    handleClickApplyFilter()
  }

  const handleFilterChange = (event, type) => {
    if (
      (event.target.value instanceof Date &&
        !isNaN(event.target.value.getTime())) ||
      dayjs.isDayjs(event.target.value) ||
      type == "Date"
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd");
    }
    if (Array.isArray(event.target.value) && event.target.value.length > 0) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : "";
      }
    }
    if (
      Array.isArray(event.target.value) &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = { ...filters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }

    setFilters(tempFilters);
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      setApplyFilterFlag(Math.random());
    }, 300);
  };

  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              email
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Link to={"/integrations"}>
              <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
                Integrations
              </MDBox>
            </Link>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "1.5em" }}
            >
              chevron_right
            </span>
            <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Email</MDBox>
          </MDBox>
        }
      />

      <MDBox
        sx={{
          position: "relative",
        }}
      >
        <DataTable
          table={{ columns: columns, rows: emailDetails || [] }}
          isSorted={true}
          entriesPerPage={{ defaultValue: filters?.limit }}
          showTotalEntries={true}
          noEndBorder
          canSearch={true}
          rowClick={handleRowClick}
          filters={filters}
          filterOptions={true}
          handleFilterChange={handleFilterChange}
          clearAllFilter={clearAllFilter}
          handleSearchChange={(text) => handleSearchChange(text, "Filters")}
          downloadable={false}
          customPagination={true}
          pageInfo={pages}
          isLoading={isLoading}
          handleClickApplyFilter={handleClickApplyFilter}
          tableHeight={"calc(100vh - 184px)"}
        />

        {emailDetails && activeEmail && (
          <MDBox
            sx={{
              height: "100%",
              width: "100%",
              zIndex: 1,
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: white.main,
              borderRadius: "10px",
              padding: "1rem",
            }}
          >
            <EmailViewer
              emailDetails={activeEmail}
              onBackClick={() => {
                setActiveEmail(null);
              }}
            />
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default Email;
