import { TableCell, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ListViewSkeleton = ({ rows = 8, columns = 2 }) => {
  return (
    <SkeletonTheme highlightColor="white">
      {Array.from({ length: rows }).map(() => (
        <TableRow>
          <TableCell sx={{ width: "50%" }}>
            <Skeleton style={{ height: "1rem", width: "200px" }} />
          </TableCell>
          {Array.from({ length: columns }).map(() => (
            <TableCell>
              <Skeleton style={{ height: "1rem", width: "150px" }} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </SkeletonTheme>
  );
};

export const GridViewSkeleton = ({ items = 8 }) => {
  return (
    <SkeletonTheme highlightColor="white">
      {Array.from({ length: items }).map(() => (
        <MDBox
          sx={{
            display: "flex",
            backgroundColor: "#f0f2f5",
            borderRadius: "10px",
            padding: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <MDBox sx={{ width: "100%", overflow: "hidden" }}>
              <MDBox
                sx={{
                  lineHeight: "1em",
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <Skeleton style={{ height: "1rem", width: "150px" }} />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <MDBox>
                  <Skeleton style={{ height: ".6rem", width: "50px" }} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ))}
    </SkeletonTheme>
  );
};
