import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { useParams } from "react-router-dom";
import LeftPanel from "./leftPanel/LeftPanel";
import { useTheme } from "@emotion/react";
import { useState } from "react";

const DeliveryDetails = () => {
  const { id } = useParams();

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              menu
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox>
            <MDBox sx={styles.infoBox}>Delivery</MDBox>
            <MDBox mr={{ md: 1, sm: 0 }} sx={{ fontSize: "16px" }}>
              {id.toUpperCase()}
            </MDBox>
          </MDBox>
        }
      />
      <MDBox
        sx={{
          width: "100%",
          display: "flex",
          height: "calc(100vh - 112px)",
          gap: "1rem",
          marginTop: "15px",
        }}
      >
        <MDBox
          sx={{
            flex: 1,
          }}
        >
          <LeftPanel deliveryId={id} />
        </MDBox>
        <MDBox
          sx={{
            maxWidth: "30%",
            display: "flex",
            width: "360px",
            justifyContent: "end",
            backgroundColor: white.main,
            borderRadius: "10px",
            boxShadow: "0px 0px 8px 1px #0000000D",
          }}
        ></MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default DeliveryDetails;
