import CustomInput from "./CustomInput";
import RadioGroup from "./RadioGroup";
import Checkbox from "./Checkbox";
// import DatePicker from "./CustomDatePicker";
import Button from "./Button";
import ListInput from "./ListInput";
import Bullet from "./Bullet";
import KeyValueSelect from "./KeyValueSelect";
import Paragraph from "./Paragraph"
import CustomAutoComplete from "./AutoComplete";
import KeyValueConst from "./KeyValueConst";
import CustomDatePicker from "./CustomDatePicker";
import Amount from "./Amount";
import CustomAutoCompleteMultiple from "./AutoCompleteMultiple";
import Dropdown from "./Dropdown";
import CustomInteger from "./Integer";

const Controls = {
    "Text":CustomInput,
    "radio":RadioGroup,
    "DropdownSingleSelect":CustomAutoComplete,
    "DropdownMultiSelect":Dropdown,
    "checkbox":Checkbox,
    "Date":CustomDatePicker,
    "datetime":CustomDatePicker,
    "date":CustomDatePicker,
    "Button":Button,
    "Integer":CustomInteger,
    "Number":CustomInteger,
    "Amount":Amount,
    "Float":CustomInteger,
    "List":ListInput,
    "Bullet":Bullet,
    "Keyvalue":KeyValueSelect,
    "Paragraph":Paragraph,
    "KeyvalueConstOptions":KeyValueConst,
    "Default":CustomInput,
}

export default Controls;