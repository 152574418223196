import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import SearchBar from "examples/searchBar/SearchBar";
import EmptyResult from "utils/emptyResult";
import ChartHolder from "./components/ChartHolder";
import InfoCard from "./components/InfoCard";

function Dashboard() {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success } = palette;
  const { pxToRem } = functions;

  const recentFiles = [];

  const cardStyle = {
    borderRadius: "10px",
    backgroundColor: white.main,
    boxShadow: "0px 0px 8px 1px #0000000D",
  };

  const storage = {
    occupied: "20%",
    trash: "11%",
  };
  return (
    <MDBox sx={{ display: "flex", gap: "1rem", height: "calc(100vh - 32px)" }}>
      <MDBox
        sx={{
          width: "25%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MDBox
          sx={{
            marginBottom: "1rem",
            padding: ".8em",
            ...cardStyle,
          }}
        >
          <MDBox>Storage</MDBox>
          <MDBox sx={{ display: "flex", gap: "2px" }} my={1}>
            <MDBox
              sx={{
                width: storage.occupied,
                backgroundColor: error.light,
                borderRadius: "100px",
                height: "5px",
              }}
            ></MDBox>
            <MDBox
              sx={{
                width: storage.trash,
                backgroundColor: warning.light,
                borderRadius: "100px",
                height: "5px",
              }}
            ></MDBox>
            <MDBox
              sx={{
                flex: 1,
                backgroundColor: primary.main,
                borderRadius: "100px",
                height: "5px",
              }}
            ></MDBox>
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              pt={0.5}
            >
              <MDBox>389GB/</MDBox>
              <MDBox color={text.disabled}>512GB</MDBox>
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                gap: "2px",
                alignItems: "center",
                cursor: "pointer",
              }}
              color={primary.main}
            >
              Upgrage Storage
              <span
                style={{ fontSize: "1.2em" }}
                class="material-symbols-outlined"
              >
                north_east
              </span>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <InfoCard
            title={"This Week"}
            iconName={"insert_chart"}
            iconColor={"#676DFF"}
            mainData={0.27}
            mainDataName={"GB"}
            sx={cardStyle}
          />
          <InfoCard
            title={"Acc. Rates"}
            iconName={"star"}
            iconColor={"#33C23A"}
            mainData={76238}
            mainDataName={"Rate"}
            sx={cardStyle}
          />
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <InfoCard
            title={"Fav. Folders"}
            iconName={"folder"}
            iconColor={"#EF6C00"}
            mainData={0.27}
            mainDataName={"Folder"}
            sx={cardStyle}
          />
          <InfoCard
            title={"Trash"}
            iconName={"delete"}
            iconColor={"#EF5350"}
            mainData={35}
            mainDataName={"File"}
            sx={cardStyle}
          />
        </MDBox>
        <MDBox
          sx={{
            height: "100%",
            padding: ".8em",
            ...cardStyle,
          }}
        >
          <MDBox
            sx={{
              border: "1px dashed " + primary.main,
              borderRadius: "10px",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MDBox color={primary.main} sx={{ textAlign: "center" }}>
              <span class="material-symbols-outlined">cloud_upload</span>

              <MDBox color={"inherit"}>Upload Files</MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          width: "35%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <MDBox
          sx={{
            ...cardStyle,
            flex: 1,
          }}
        >
          <ChartHolder
            title={"Recent Activity"}
            id={"recentActivity"}
            getData={() => {}}
            showFilter={false}
            chartProps={{
              type: "bar",
              grid: { vertical: false },
              name: "name",
            }}
          ></ChartHolder>
        </MDBox>
        <MDBox
          sx={{
            ...cardStyle,
            flex: 1,
          }}
        >
          <ChartHolder
            title={"File Share Activity"}
            id={"fileShareActivity"}
            getData={() => {}}
            showFilter={false}
            chartProps={{
              type: "pie",
              graph: {
                innerRadius: 30,
                outerRadius: 70,
                paddingAngle: 3,
                showPercentage: false,
              },
              name: "name",
            }}
          ></ChartHolder>
        </MDBox>
      </MDBox>
      <MDBox sx={{ flex: 1, height: "100%", padding: ".8em", ...cardStyle }}>
        <MDBox mt={1} mb={2}>
          <SearchBar
            // handleKeyUp={searchSessionsByName}
            // inputRef={searchRef}
            placeholder={"Search Files"}
            sx={{ border: "none", backgroundColor: primary.light }}
            fontColor={primary.main}
          />
        </MDBox>
        <MDBox>Recently Opened Files</MDBox>
        <MDBox sx={{ height: "calc(100% - 80px)", position: "relative" }}>
          {recentFiles.length > 0 ? (
            {}
          ) : (
            <EmptyResult text={"No Recent Files"} />
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
export default Dashboard;
