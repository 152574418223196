import { useTheme } from "@emotion/react";
import {
  Checkbox,
  Icon,
  Table,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import Confirmation from "examples/dialogs/Confirmation";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import FileUploadDialog from "examples/dialogs/FileUploadDialog";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import SearchBar from "examples/searchBar/SearchBar";
import { formatDate } from "examples/Tables/DataTable/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DialogWrapper from "utils/dialog/dialogWrapper";
import FileModel from "./components/FileViewer";
import SearchFilesDialog from "./components/SearchFilesDialog";
import TextWithIcon from "./components/TextWithIcon";
import useFolder from "./hooks/useFolder";
import { folderService } from "./service/folderService";
import ListView from "./components/ListView";
import GridView from "./components/GridView";
import { setGlobalState } from "Global";
import { folderAPI } from "api";
import { useGlobalState } from "Global";
import ShareDialog from "./components/ShareDialog";

const Folders = () => {
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;

  const { id } = useParams();
  const navigation = useNavigate();
  const [userDetails] = useGlobalState("userDetails");
  const [isLoading, setIsLoading] = useState(false);

  const {
    foldersAndFiles,
    setFoldersAndFiles,
    users,
    teamList,
    isLoadingFolder,
  } = useFolder(id);
  const [previewFile, setPreviewFile] = useState(null);
  const [showCreateFolderDialog, setShowCreateFolderDialog] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [showSearchFilesDialog, setShowSearchFilesDialog] = useState(false);
  const [showDeleteFolderDialog, setShowDeleteFolderDialog] = useState(null);
  const [showDeleteFileDialog, setShowDeleteFileDialog] = useState(null);
  const [showShareWithDialog, setShowShareWithDialog] = useState(null);
  const [selectedFolderDetails, setSelectedFolderDetails] = useState(null);

  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [showMultiDelete, setShowMultiDelete] = useState(false);
  const [showMultiShare, setShowMultiShare] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    files: [],
    folders: [],
  });
  const [islistView, setIsListView] = useState(true);

  const handleFolderClick = (id) => {
    navigation("/folders/" + id);
  };

  const defaultInputs = {
    folder_name: {
      type: "Text",
      editable: true,
      required: true,
      value: "New Folder",
    },
    shared_with: {
      label: "Shared With",
      type: "DropdownMultiSelect",
      options: [...users, ...teamList],
      dataKey: "email_id",
      editable: true,
    },
  };

  useEffect(() => {
    if (showShareWithDialog) {
      const dynamicFunction = showShareWithDialog.folder_id
        ? folderAPI.getFolderDetails
        : folderAPI.getFileDetails;
      setIsLoading(true);
      dynamicFunction(
        showShareWithDialog.folder_id || showShareWithDialog.file_id
      )
        .then((res) => {
          if (res.status === 200) {
            setSelectedFolderDetails(res.data);
          }
        })
        .catch(() => {
          setShowShareWithDialog(null);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [showShareWithDialog]);

  const handleCreateFolder = (data) => {
    setIsLoading(true);
    folderService
      .createFolder(
        {
          ...data,
          shared_with: [...(data.shared_with || []), userDetails.email],
        },
        foldersAndFiles?.folder_id
      )
      .then((res) => {
        if (res.status == 200) {
          setFoldersAndFiles((pre) => ({
            ...(pre || {}),
            subfolders: [
              ...(pre?.subfolders || []),
              {
                folder_id: res.data.folder_id,
                folder_name: data.folder_name,
                owner_email: userDetails.email,
                files: [],
                folders: [],
                created_at: new Date(),
              },
            ],
          }));
          setGlobalState("error", {
            open: true,
            message: "Folder Created successfully!",
            type: "success",
            code: 200,
          });
          setShowCreateFolderDialog(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setShowCreateFolderDialog(false);
        setIsLoading(false);
      });
  };
  const handleDeleteFolder = (id) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      folderService
        .deleteFolder(id)
        .then((res) => {
          if (res.status == 200) {
            let tempSubFolders = foldersAndFiles.subfolders.filter(
              (folders) => folders.folder_id !== id
            );
            setFoldersAndFiles((pre) => ({
              ...pre,
              subfolders: tempSubFolders,
            }));
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowDeleteFolderDialog(null);
          setIsLoading(false);
        });
    });
  };

  const handleUploadFile = async (files) => {
    setIsLoading(true);
    const file = files[0];
    const fileMetadata = {
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
    };
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("folder_id", foldersAndFiles.folder_id);
    formdata.append("metadata", JSON.stringify(fileMetadata));

    folderService
      .uploadFile(formdata)
      .then((res) => {
        if (res.status == 200) {
          setFoldersAndFiles((pre) => ({
            ...pre,
            files: [
              ...pre.files,
              {
                file_id: res.data.file_id,
                filename: file.name,
                owner_email: userDetails.email,
                rawFile: file,
                created_at: new Date(),
              },
            ],
          }));
          setGlobalState("error", {
            open: true,
            message: "File uploaded successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowFileUploadDialog(false);
        setIsLoading(false);
      });
  };

  const handleDeleteFile = (id) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      folderService
        .deleteFile(id)
        .then((res) => {
          if (res.status == 200) {
            let tempFiles = foldersAndFiles.files.filter(
              (file) => file.file_id !== id
            );
            setFoldersAndFiles((pre) => ({
              ...pre,
              files: tempFiles,
            }));
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowDeleteFileDialog(null);
          setIsLoading(false);
        });
    });
  };

  const handleShare = (id, shareWith, removedList, type, ownerEmail) => {
    // for team data extracting _id
    shareWith = shareWith.map((data) => {
      if (typeof data == "object") {
        return data._id || data.email_id;
      }
      return data;
    });
    if (ownerEmail) {
      removedList = removedList.filter((id) => id != ownerEmail);
    }
    return new Promise((resolve, reject) => {
      setIsLoading(true);

      const dynamicFunction =
        type === "folder" ? folderService.shareFolder : folderService.shareFile;

      dynamicFunction(id, shareWith, removedList)
        .then((res) => {
          if (res.status == 200) {
            if (type === "folder" && id === foldersAndFiles.folder_id) {
              setFoldersAndFiles((pre) => ({
                ...pre,
                shared_with: shareWith,
              }));
            } else if (type === "folder") {
              setFoldersAndFiles((pre) => ({
                ...pre,
                subfolders: pre.subfolders.map((data) =>
                  data.folder_id === id
                    ? { ...data, shared_with: shareWith }
                    : data
                ),
              }));
            } else {
              setFoldersAndFiles((pre) => ({
                ...pre,
                files: pre.files.map((data) =>
                  data.file_id === id
                    ? { ...data, shared_with: shareWith }
                    : data
                ),
              }));
            }
            resolve();
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          setShowShareWithDialog(null);
          setIsLoading(false);
        });
    });
  };

  const handleSelect = (value, id, type) => {
    if (type === "all") {
      setSelectedItems({
        files: foldersAndFiles?.files.map((data) => data.file_id),
        folders: foldersAndFiles?.subfolders.map((data) => data.folder_id),
      });
    } else {
      let tempItems = { ...selectedItems };
      if (value) {
        tempItems[type].push(id);
      } else {
        tempItems[type] = tempItems[type].filter((ItemId) => ItemId != id);
      }
      setSelectedItems(tempItems);
    }
  };

  const handleMultiDownload = async () => {
    const selectedFiles = foldersAndFiles.files.filter((data) =>
      selectedItems.files.includes(data.file_id)
    );

    for (let i = 0; i < selectedFiles.length; i++) {
      const data = selectedFiles[i];
      folderService.downloadFile(data.s3_url, data.name);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    setSelectedItems({
      files: [],
      folders: [],
    });
  };

  const handleMultiDelete = () => {
    if (data.shared_with.length > 0) {
      setIsLoading(true);
      let promises = [];
      selectedItems.files.forEach((id) => {
        promises.push(handleDeleteFile(id));
      });
      selectedItems.folders.forEach((id) => {
        promises.push(handleDeleteFolder(id));
      });

      Promise.all(promises)
        .then(() => {
          setGlobalState("error", {
            open: true,
            message: "All items have been deleted successfully!",
            type: "success",
            code: 200,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setShowMultiShare(false);
          setIsLoading(false);
          setSelectedItems({
            files: [],
            folders: [],
          });
        });
    }
  };

  const handleMultiShare = (data) => {
    if (data.shared_with.length > 0) {
      setIsLoading(true);

      let promises = [];
      // selectedItems.files.forEach((id) => {
      //   promises.push(handleShare(id, data.shared_with, "file"));
      // });
      selectedItems.folders.forEach((id) => {
        promises.push(handleShare(id, data.shared_with, "folder"));
      });

      Promise.all(promises)
        .then(() => {
          setGlobalState("error", {
            open: true,
            message: "All items have been shared successfully!",
            type: "success",
            code: 200,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setShowMultiShare(false);
          setIsLoading(false);
          setSelectedItems({
            files: [],
            folders: [],
          });
        });
    }
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={{ ...styles.iconHolder, fontSize: "1.2em" }}>
            <span class="material-symbols-outlined">folder</span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Documents</MDBox>
        }
        rightContent={
          <MDBox sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <SearchBar
              onClick={() => {
                setShowSearchFilesDialog(true);
              }}
              placeholder={"Search Files"}
              sx={{ border: "none", backgroundColor: primary.light }}
              fontColor={primary.main}
            />
            <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{ color: primary.main, cursor: "pointer" }}
                >
                  more_vert
                </span>
              }
            >
              <TextWithIcon
                onClick={() => setShowCreateFolderDialog(true)}
                iconName={"create_new_folder"}
                title={"Create New Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => setShowFileUploadDialog(true)}
                iconName={"upload_file"}
                title={"Upload File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => {
                  setShowShareWithDialog(foldersAndFiles);
                }}
                iconName={"group_add"}
                title={"Share Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup>
          </MDBox>
        }
      />

      <MDBox
        sx={{
          borderRadius: "10px",
          backgroundColor: white.main,
          height: "calc(100% - 80px)",
          overflow: "auto",
          boxShadow: "0px 0px 8px 1px #0000000D",
          padding: "1rem",
        }}
      >
        <MDBox
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <TextWithIcon
              onClick={() => handleFolderClick("")}
              iconName={"home"}
              title={"Organisation"}
            />
            {foldersAndFiles?.folder_location?.map((data, index) => (
              <TextWithIcon
                onClick={() => handleFolderClick(data.folder_id)}
                pb={"2px"}
                iconName={"chevron_right"}
                title={data.folder_name}
              />
            ))}

            {/* <QuickActionPopup
              icon={
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: "1.3em",
                    borderRadius: "100%",
                    padding: "3px",
                    color: primary.main,
                    backgroundColor: primary.light,
                    cursor: "pointer",
                  }}
                >
                  add
                </span>
              }
              sx={{
                left: "10px",
                top: "1.8em",
                width: "200px",
              }}
            >
              <TextWithIcon
                onClick={() => setShowCreateFolderDialog(true)}
                iconName={"create_new_folder"}
                title={"Create New Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
              <TextWithIcon
                onClick={() => setShowFileUploadDialog(true)}
                iconName={"upload_file"}
                title={"Upload File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  transition: "background-color .3s",
                  "&:hover": {
                    backgroundColor: grey[200],
                  },
                }}
              />
            </QuickActionPopup> */}
          </MDBox>
          <MDBox
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {showMultiSelect && (
              <MDBox
                sx={{
                  height: "30px",
                  borderRadius: "100px",
                  background: grey[200],
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  // width: "220px",
                  padding: "5px",
                }}
              >
                <MDBox color={text.secondary} sx={{ whiteSpace: "nowrap" }}>
                  {selectedItems.files.length + selectedItems.folders.length}{" "}
                  selected
                </MDBox>
                <span
                  class="material-symbols-outlined"
                  onClick={() => {
                    selectedItems.files.length + selectedItems.folders.length >
                    0
                      ? setShowMultiShare(true)
                      : () => {};
                  }}
                  style={{
                    color: text.secondary,
                    fontSize: "1.4em",
                    cursor: "pointer",
                  }}
                >
                  group_add
                </span>
                <span
                  class="material-symbols-outlined"
                  onClick={() => {
                    selectedItems.files.length + selectedItems.folders.length >
                    0
                      ? setShowMultiDelete(true)
                      : () => {};
                  }}
                  style={{
                    color: text.secondary,
                    fontSize: "1.4em",
                    cursor: "pointer",
                  }}
                >
                  delete
                </span>
                {selectedItems.files.length > 0 && (
                  <span
                    class="material-symbols-outlined"
                    onClick={() => {
                      handleMultiDownload();
                    }}
                    style={{
                      color: text.secondary,
                      fontSize: "1.4em",
                      cursor: "pointer",
                    }}
                  >
                    download
                  </span>
                )}
                <Tooltip title={"Close"}>
                  <MDBox sx={{ display: "flex" }}>
                    <span
                      class="material-symbols-outlined"
                      onClick={() => {
                        setShowMultiSelect(false);
                        setSelectedItems({
                          files: [],
                          folders: [],
                        });
                      }}
                      style={{
                        color: text.secondary,
                        backgroundColor: white.main,
                        borderRadius: "100%",
                        fontSize: "1.2em",
                        padding: "3px",
                        cursor: "pointer",
                        "& :hover": {
                          backgroundColor: grey[200],
                        },
                      }}
                    >
                      close
                    </span>
                  </MDBox>
                </Tooltip>
              </MDBox>
            )}
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                whiteSpace: "nowrap",
              }}
            >
              {!showMultiSelect && (
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: "1.3em",
                    borderRadius: "100%",
                    padding: "3px",
                    color: primary.main,
                    backgroundColor: primary.light,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowMultiSelect(true);
                  }}
                >
                  checklist_rtl
                </span>
              )}
              <TextWithIcon
                onClick={() => setShowCreateFolderDialog(true)}
                iconName={"create_new_folder"}
                title={"Create Folder"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  borderRadius: "100px",
                  padding: "3px 5px",
                  color: primary.main,
                  cursor: "pointer",
                }}
              />
              <TextWithIcon
                onClick={() => setShowFileUploadDialog(true)}
                iconName={"upload"}
                title={"Upload File"}
                pb={"2px"}
                sx={{
                  padding: "5px 1rem",
                  borderRadius: "100px",
                  padding: "3px 5px",
                  color: primary.main,
                  cursor: "pointer",
                }}
              />
              <MDBox sx={{ display: "flex" }}>
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: "1.3em",
                    borderRadius: "100%",
                    padding: "3px",
                    color: primary.main,
                    backgroundColor: islistView ? primary.light : "",
                    cursor: "pointer",
                    borderRadius: "100px 0 0 100px",
                    border: "1px solid " + primary.main,
                    paddingLeft: "10px",
                  }}
                  onClick={() => setIsListView(true)}
                >
                  reorder
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{
                    fontSize: "1.3em",
                    borderRadius: "100%",
                    padding: "3px",
                    color: primary.main,
                    borderRadius: "0 100px 100px 0",
                    backgroundColor: !islistView ? primary.light : "",
                    cursor: "pointer",
                    border: "1px solid " + primary.main,
                    paddingRight: "10px",
                    borderLeft: "none",
                  }}
                  onClick={() => setIsListView(false)}
                >
                  grid_view
                </span>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox sx={{ width: "100%" }}>
          {islistView ? (
            <ListView
              showMultiSelect={showMultiSelect}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              foldersAndFiles={foldersAndFiles}
              handleSelect={handleSelect}
              handleFolderClick={handleFolderClick}
              setPreviewFile={setPreviewFile}
              setShowShareWithDialog={setShowShareWithDialog}
              setShowDeleteFileDialog={setShowDeleteFileDialog}
              setShowDeleteFolderDialog={setShowDeleteFolderDialog}
              isLoading={isLoadingFolder}
            />
          ) : (
            <GridView
              showMultiSelect={showMultiSelect}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              foldersAndFiles={foldersAndFiles}
              handleSelect={handleSelect}
              handleFolderClick={handleFolderClick}
              setPreviewFile={setPreviewFile}
              setShowShareWithDialog={setShowShareWithDialog}
              setShowDeleteFileDialog={setShowDeleteFileDialog}
              setShowDeleteFolderDialog={setShowDeleteFolderDialog}
              isLoading={isLoadingFolder}
            />
          )}
        </MDBox>
      </MDBox>
      {previewFile && (
        <DialogWrapper handleClose={() => setPreviewFile(null)} width={"35%"}>
          <FileModel file={previewFile} />
        </DialogWrapper>
      )}

      {showCreateFolderDialog && (
        <DataInputDialog
          open={showCreateFolderDialog}
          handleClose={() => {
            setShowCreateFolderDialog(null);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            handleCreateFolder(data);
          }}
          buttonName={"Create Folder"}
          heading={"Create New Folder"}
          iconName={"create_new_folder"}
          isLoading={isLoading}
        />
      )}
      <Confirmation
        open={showDeleteFolderDialog}
        onClose={() => {
          setShowDeleteFolderDialog(null);
        }}
        onAccept={() =>
          handleDeleteFolder(showDeleteFolderDialog?.folder_id)
            .then(() => {
              setGlobalState("error", {
                open: true,
                message: "Folder deleted successfully!",
                type: "success",
                code: 200,
              });
            })
            .catch((e) => {})
        }
        acceptLable={"Delete"}
        title={`Do you really want to delete folder: ${showDeleteFolderDialog?.folder_name}?`}
        isLoading={isLoading}
      />
      <Confirmation
        open={showMultiDelete}
        onClose={() => {
          setShowMultiDelete(null);
        }}
        onAccept={() => handleMultiDelete()}
        acceptLable={"Delete"}
        title={`Do you really want to delete selected items`}
        isLoading={isLoading}
      />
      <Confirmation
        open={showDeleteFileDialog}
        onClose={() => {
          setShowDeleteFileDialog(null);
        }}
        onAccept={() => handleDeleteFile(showDeleteFileDialog?.file_id).then(() => {
          setGlobalState("error", {
            open: true,
            message: "File deleted successfully!",
            type: "success",
            code: 200,
          });
        }).catch(() => {})}
        acceptLable={"Delete"}
        title={`Do you really want to delete File: ${showDeleteFileDialog?.filename}?`}
        isLoading={isLoading}
      />
      {showFileUploadDialog && (
        <FileUploadDialog
          open={showFileUploadDialog}
          onClose={() => {
            setShowFileUploadDialog(false);
          }}
          multiFilesAllowed={false}
          handleUpload={handleUploadFile}
          isLoading={isLoading}
        />
      )}
      {showSearchFilesDialog && (
        <SearchFilesDialog
          open={showSearchFilesDialog}
          handleClose={() => {
            setShowSearchFilesDialog(false);
          }}
        />
      )}
      {showShareWithDialog && (
        <ShareDialog
          open={showShareWithDialog}
          topInfo={`${showShareWithDialog.owner_email || selectedFolderDetails?.owner_email} (Owner)`}
          handleClose={() => {
            setShowShareWithDialog(null);
            setSelectedFolderDetails(null);
          }}
          defaultInputs={{
            shared_with: {
              ...defaultInputs.shared_with,
              value:
                selectedFolderDetails?.shared_with?.filter(
                  (id) => id !== (showShareWithDialog.owner_email || selectedFolderDetails?.owner_email)
                ) || [],
            },
          }}
          shareWith={
            selectedFolderDetails?.shared_with?.filter(
              (id) => id !== (showShareWithDialog.owner_email || selectedFolderDetails?.owner_email)
            ) || []
          }
          handleSubmit={(data, removedList) => {
            showShareWithDialog?.folder_id != null ||
            showShareWithDialog?.folder_id != undefined
              ? handleShare(
                  showShareWithDialog?.folder_id,
                  data.shared_with,
                  removedList,
                  "folder",
                  showShareWithDialog.owner_email || selectedFolderDetails?.owner_email
                )
                  .then(() => {
                    setGlobalState("error", {
                      open: true,
                      message: "Folder shared successfully!",
                      type: "success",
                      code: 200,
                    });
                  })
                  .catch(() => {})
              : handleShare(
                  showShareWithDialog?.file_id,
                  data.shared_with,
                  removedList,
                  "file",
                  showShareWithDialog.owner_email || selectedFolderDetails?.owner_email
                )
                  .then(() => {
                    setGlobalState("error", {
                      open: true,
                      message: "File shared successfully!",
                      type: "success",
                      code: 200,
                    });
                  })
                  .catch(() => {});
          }}
          buttonName={"Submit"}
          heading={
            showShareWithDialog?.folder_id != null ||
            showShareWithDialog?.folder_id != undefined
              ? `Share Folder (${
                  showShareWithDialog.folder_name || selectedFolderDetails.folder_name || "This"
                }) With`
              : `Share File (${showShareWithDialog.filename}) With`
          }
          iconName={"group_add"}
          isLoading={isLoading}
        />
      )}
      {showMultiShare && (
        <DataInputDialog
          open={showMultiShare}
          handleClose={() => {
            setShowMultiShare(null);
          }}
          defaultInputs={{
            shared_with: {
              ...defaultInputs.shared_with,
              value: [],
            },
          }}
          handleSubmit={(data) => {
            handleMultiShare(data);
          }}
          buttonName={"Submit"}
          heading={"Share With"}
          iconName={"group_add"}
          isLoading={isLoading}
        />
      )}
    </DashboardLayout>
  );
};

export default Folders;
