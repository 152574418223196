import { toPng } from "html-to-image";

export const getDateRange = (tab) => {
  const now = new Date();
  let from, to;

  switch (tab) {
    case "This Week":
      const weekStart = now.getDate() - now.getDay();
      from = new Date(now.setDate(weekStart));
      to = new Date(now.setDate(weekStart + 6));
      break;
    case "This Quarter":
      const currentMonth = now.getMonth();
      const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
      from = new Date(now.getFullYear(), quarterStartMonth, 1);
      to = new Date(now.getFullYear(), quarterStartMonth + 3, 0);
      break;
    case "Last 6 Months":
      from = new Date(now.setMonth(now.getMonth() - 6));
      to = new Date();
      break;
    case "This Year":
      from = new Date(now.getFullYear(), 0, 1);
      to = new Date(now.getFullYear(), 11, 31);
      break;
    default:
      from = null;
      to = null;
      break;
  }
  from?.setHours(0, 0, 0, 0);
  return {
    from: from?.toISOString() || "",
    to: to?.toISOString() || "",
  };
};

export const getFiltersCount = (filters, ignoreFilterList) => {
  let count = 0;
  if (filters) {
    Object.keys(filters).forEach((filterName) => {
      if (!ignoreFilterList.includes(filterName)) {
        if (Array.isArray(filters[filterName])) {
          count += filters[filterName]?.length;
        } else if (filterName === "from_date" && filters[filterName]) {
          count++;
        }
      }
    });
  }
  return count;
};

export const downloadImage = (DomObject, name) => {
  if (DomObject)
    toPng(DomObject, {
      backgroundColor: "white",
      style: {
        backgroundColor: "white",
      },
    }).then((dataUrl) => {
      const a = document.createElement("a");

      a.setAttribute("download", name + ".png");
      a.setAttribute("href", dataUrl);
      a.click();
    });
};

export const calculateCombinedPercentage = (...percentages) => {
  let totalMultiplier = 1;

  percentages.forEach((percentage) => {
    totalMultiplier *= 1 + percentage / 100;
  });

  const combinedIncrease = (totalMultiplier - 1) * 100;
  return combinedIncrease;
};
