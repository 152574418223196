import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

//  React components
import MDTypography from 'components/MDTypography'

// Custom styles for MDProgress
import MDProgressRoot from 'components/MDProgress/MDProgressRoot'
import MDBox from 'components/MDBox'

const MDProgress = forwardRef(({ variant, text, color, textStyle, textColor, value, label, ...rest }, ref) => (
  <>
    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
      <MDBox sx={textStyle} color={textColor || "text"}>
        {value}%
      </MDBox>
      <MDBox sx={{ width: '100%' }}>
        <MDProgressRoot
          {...rest}
          ref={ref}
          variant="determinate"
          value={value}
          ownerState={{ color, value, variant }}
        />
      </MDBox>
      {text && (
        <MDBox sx={textStyle} color={textColor || "text"}>
          {text}h
        </MDBox>
      )}
    </div>
  </>
))

// Setting default values for the props of MDProgress
MDProgress.defaultProps = {
  variant: 'contained',
  color: 'info',
  value: 0,
  label: false
}

// Typechecking props for the MDProgress
MDProgress.propTypes = {
  variant: PropTypes.oneOf(['contained', 'gradient']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark'
  ]),
  value: PropTypes.number,
  label: PropTypes.bool
}

export default MDProgress
