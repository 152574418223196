import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { TabContext, TabPanel } from "@mui/lab";
import { AppBar, Icon, Tab, Tabs } from "@mui/material";
import { setGlobalState } from "Global";
// import { caseAPI } from "api";
// import AdvancedAnalytics from "layouts/dashboard/advancedAnalytics";
// import AgentAnalytics from "layouts/dashboard/agentAnalytics";
// import BasicAnalytics from "layouts/dashboard/basicAnalytics";
// import ProcessMining from "layouts/dashboard/processMining";
import { Component, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import OTDDashboard from "./components/otdDashboard";
import MainDashboard from "./components/MainDashboard/mainDashboard";
import OTCDashboard from "./components/otcDashboard";
import VFRDashboard from "./components/vfrDashboard";
import OTC2Dashboard from "./components/otc2Dashboard";
import OTD2Dashboard from "./components/otd2Dashboard";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import DocDashboard from "layouts/docDashboard/Dashboard";

function Dashboard(props) {
  const navigation = useNavigate();
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [days, setDays] = useState(90);
  const [state, setState] = useState({});

  const [filter, setFilter] = useState({
    days: 90,
    resolved_only: false,
    level: 1,
    variants: [],
  });

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const tabs = [
    {
      name: "Main",
      component: <MainDashboard />,
    },
    {
      name: "OTC-BASE",
      component: <OTCDashboard />,
    },
    {
      name: "OTC-DSADAKAR",
      component: <OTC2Dashboard />,
    },
    {
      name: "OTD-BASE",
      component: <OTDDashboard />,
    },
    {
      name: "OTD-PHC",
      component: <OTD2Dashboard />,
    },
    {
      name: "VFR",
      component: <VFRDashboard />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              analytics
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Dashboard</MDBox>
        }
      />
      <MDBox>
        {/* <MDBox
          sx={{
            display: "flex",
            fontSize: typography.size["sm"],
            fontWeight: typography.fontWeightMedium,
            borderBottom: `1px solid ${palette.grey[300]}`,
            gap: "1em",
          }}
        >
          {tabs.map((tab, index) => (
            <MDBox
              key={index}
              sx={{
                padding: pxToRem(8),
                cursor: "pointer",
                gap: "5px",
                display: "flex",
                paddingBottom: pxToRem(5),
                paddingTop: 0,
                borderBottom:
                  activeTab.name === tab?.name
                    ? `2px solid ${palette.primary.main}`
                    : ``,
              }}
              color={palette.text.header}
              onClick={() => setActiveTab(tab)}
            >
              {tab?.name}
            </MDBox>
          ))}
        </MDBox> */}
        <MDBox>{activeTab.component}</MDBox>
        {activeTab.name === "Main" && <DocDashboard />}
      </MDBox>
    </DashboardLayout>
  );
}
export default Dashboard;
