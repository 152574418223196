import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import Chart from "examples/chart/Chart";
import { useEffect, useRef, useState } from "react";
import { downloadImage, getFiltersCount } from "../utils";
import ChartHolderPopup from "./ChartHolderPopup";

const initialFilters = {
  case_state: [],
  age_bucket: [],
  category: [],
  sku: [],
  reason_codes: [],
  workflow_id: [],
  customer_name: [],
  claim_type: [],
  assigned_agent: [],
  claim_status: [],
  from_date: "",
  to_date: "",
  search: "",
  limit: 50,
  offset: 0,
};

const ignoreFilterList = [
  "days",
  "limit",
  "offset",
  "search",
  "from_date",
  "to_date",
  "category",
  "sku",
];

const dummyData = [
  {
    name: "day 1",
    uploads: 4000,
    searches: 2400,
  },
  {
    name: "day 2",
    uploads: 3000,
    searches: 1398,
  },
  {
    name: "day 3",
    uploads: 2000,
    searches: 9800,
  },
  {
    name: "day 4",
    uploads: 2780,
    searches: 3908,
  },
];

const ChartHolder = ({
  title,
  children,
  chartProps,
  getData,
  id,
  showFilter,
  filterOptions,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const storedFilters = {};
  const [chartData, setChartData] = useState(null);
  const [filters, setFilters] = useState(storedFilters?.[id] || initialFilters);
  const [filtersCount, setFiltersCount] = useState(0);
  const [tempFilters, setTempFilters] = useState(initialFilters);
  const [isPopupActive, setIsPopupActive] = useState(false);

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const imageRef = useRef(null);

  const handleSelectTab = (tab, dateRange) => {
    if (tab === "Clear") {
      setActiveTab("");
      setFilters((pre) => ({ ...pre, from_date: "", to_date: "" }));
    } else {
      setActiveTab(tab);
      setFilters((pre) => ({
        ...pre,
        from_date: dateRange?.from || "",
        to_date: dateRange?.to || "",
      }));
    }
  };

  useEffect(() => {
    setTempFilters(filters);
    setFiltersCount(getFiltersCount(filters, ignoreFilterList));
    setChartData(dummyData);
    if (getData && filters) {
      getData(filters)
        ?.then((data) => {
          setChartData(data);
        })
        .catch((err) => {});
    }
  }, [getData, filters]);

  const handleFilterChange = (event) => {
    if (
      typeof event.target.value == typeof [] &&
      event.target.value.length > 0
    ) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : "";
      }
    }

    if (
      typeof event.target.value == typeof [] &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let filters = { ...tempFilters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      filters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      filters[event.target.name] = [];
    }
    setTempFilters(filters);
  };

  const handleClosePopup = () => {
    setIsPopupActive(false);
    setTempFilters(filters);
  };

  const handleApplyFiter = () => {
    setFilters(tempFilters);
  };

  const handleClick = () => {
    setIsPopupActive(true);
  };

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
        padding: "1em",
        position: "relative",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <MDBox color={text.main}>{title}</MDBox>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: "2em" }}>
          <MDBox sx={{ display: "flex", alignItems: "center", gap: ".5em" }}>
            {/* {showFilter !== false && <MDBox
              sx={{
                backgroundColor: primary.light,
                fontWeight: typography.fontWeightMedium,
                borderRadius: "1em",
                width: "40px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }}
              color={text.disabled}
            >
              1M
            </MDBox>} */}
          </MDBox>

          <MDBox
            sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}
            onClick={handleClick}
          >
            {showFilter !== false && (
              <>
                <MDBox sx={{ position: "relative" }}>
                  {filtersCount !== 0 && (
                    <MDBox
                      sx={{
                        position: "absolute",
                        backgroundColor: primary.main,
                        top: "-1.5em",
                        right: "-3.2em",
                        transform: "scale(.8)",
                        width: "20px",
                        height: "20px",
                        textAlign: "center",
                        fontSize: "12px",
                        borderRadius: "100px",
                      }}
                      color={white.main}
                    >
                      {filtersCount}
                    </MDBox>
                  )}
                </MDBox>
                <span
                  style={{
                    color: text.disabled,
                    fontSize: "1.5em",
                    cursor: "pointer",
                  }}
                  class="material-symbols-outlined"
                >
                  filter_alt
                </span>
              </>
            )}
            <span
              style={{
                color: text.disabled,
                fontSize: "1.5em",
                cursor: "pointer",
              }}
              class="material-symbols-outlined"
            >
              open_in_full
            </span>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          marginRight: "1.5em",
        }}
        ref={chartProps ? imageRef : null}
      >
        <Chart {...chartProps} data={chartData} />
      </MDBox>
      <MDBox
        sx={{
          position: "absolute",
          top: "100%",
          left: "100%",
          transform: "translate(-2.5em, -2em)",
          width: "1.5em",
          height: "1.5em",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <span
          style={{
            color: text.disabled,
            fontSize: "1.5em",
            cursor: "pointer",
          }}
          class="material-symbols-outlined"
          onClick={() => downloadImage(imageRef.current, title)}
        >
          photo_camera
        </span>
      </MDBox>
      {isPopupActive && (
        <ChartHolderPopup
          title={title}
          tabs={tabs}
          activeTab={activeTab}
          handleSelectTab={handleSelectTab}
          chart={<Chart {...chartProps} data={chartData} />}
          handleFilterChange={handleFilterChange}
          handleClosePopup={handleClosePopup}
          handleApplyFiter={handleApplyFiter}
          filters={tempFilters}
          filterOptions={filterOptions}
          showFilter={showFilter !== false}
          ignoreFilterList={ignoreFilterList}
        />
      )}
    </MDBox>
  );
};

export default ChartHolder;
