import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Skeleton from "react-loading-skeleton";
import AnalyticsCardSkeleton from "skeletons/dashboard/analyticsCard";

function AnalyticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  width = "15rem",
  isLoading = false,
}) {
  return (
    <Card sx={{ width: width }}>
      {isLoading && <AnalyticsCardSkeleton />}
      {isLoading == false && (
        <>
          <MDBox display="flex" pt={1} px={2} mb={-1}>
            <MDBox
              lineHeight={2}
              fontSize={"large !important"}
              className={"font-unset"}
            >
              <MDBox sx={{ fontSize: "1.2rem !important", fontWeight: "bold" }}>
                {count}
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider />
          <MDBox pb={2} px={2}>
            <MDTypography
              component="p"
              variant="button"
              color="text"
              display="flex"
            >
              <MDTypography variant="button" fontWeight="light" color="text">
                {title}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </>
      )}
    </Card>
  );
}

// Setting default values for the props of AnalyticsCard
AnalyticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the AnalyticsCard
AnalyticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default AnalyticsCard;
