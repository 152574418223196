import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";

const TextWithIcon = ({
  iconName,
  title,
  onClick,
  color,
  fontSize,
  pb,
  sx,
}) => {
  const { palette } = useTheme();
  const { text } = palette;

  return (
    <MDBox
      color={color || text.secondary}
      sx={{
        display: "flex",
        gap: "5px",
        alignItems: "center",
        cursor: "pointer",
        ...sx,
      }}
      onClick={onClick}
    >
      <span
        class="material-symbols-outlined"
        style={{ fontSize: fontSize || "1.3em", paddingBottom: pb || "5px" }}
      >
        {iconName}
      </span>
      <MDBox color={"inherit"} sx={{ whiteSpace: "nowrap", fontSize: "inherit" }}>
        {title}
      </MDBox>
    </MDBox>
  );
};

export default TextWithIcon;
