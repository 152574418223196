import { teamsManagementAPI } from "api";
import { usersManagementAPI } from "api";
import { formatDate } from "examples/Tables/DataTable/utils";
import { folderService } from "layouts/folders/service/folderService";
import { useEffect, useState } from "react";

const columnData = [
  {
    accessor: "_id",
    type: "id",
  },
  {
    accessor: "username",
    type: "text",
  },
  {
    accessor: "email_id",
    type: "text",
  },
  {
    accessor: "name",
    type: "text",
  },
  {
    accessor: "permission",
    type: "text",
  },
  {
    accessor: "team_id",
    type: "text",
  },
];

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamList] = useState([]);

  const getUsers = () => {
    setIsLoading(true);
    folderService
      .getAllUsers()
      .then((res) => {
        if (res.status == 200) {
          setUsers(res.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getAllTeams = () => {
    setIsLoading(true)
    teamsManagementAPI
      .getAllTeams()
      .then((res) => {
        if (res.status == 200) {
          setTeamList(res.data);
        }
      })
      .catch(() => {}).finally(() => {
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getUsers();
    getAllTeams();
  }, []);

  return {
    columns: columnData,
    users,
    setUsers,
    setIsLoading,
    isLoading,
    teamList,
  };
};

export default useUsers;
