import { docstoreUserAPI, folderAPI } from "api";
import docIcon from "assets/images/icons/files/doc.svg";
import emptyIcon from "assets/images/icons/files/empty.svg";
import jpgIcon from "assets/images/icons/files/jpg.svg";
import pdfIcon from "assets/images/icons/files/pdf.svg";
import pngIcon from "assets/images/icons/files/png.svg";
import txtIcon from "assets/images/icons/files/txt.svg";
import xlsIcon from "assets/images/icons/files/xls.svg";

export const folderService = {
  getFolderAndFilesDetails(data) {
    const body = {
      folder_name: "",
      folder_id: "",
      parent_id: "",
      shared_with: [],
      search_query: "null",
      vector_search: false,
      file_id: "",
    };
    return folderAPI.getFolderAndFilesDetails({ ...body, ...data });
  },
  createFolder(data, parentId = "") {
    return folderAPI.createFolder({ ...data, parent_id: parentId });
  },
  uploadFile(data) {
    return folderAPI.uploadFile(data);
  },
  deleteFolder(folderId) {
    return folderAPI.deleteFolder({ folder_id: folderId });
  },
  deleteFile(fileId) {
    return folderAPI.deleteFile({ file_id: fileId });
  },
  searchFiles(query, isVectorSearch) {
    return folderAPI.searchFiles(query, isVectorSearch);
  },
  shareFile(id, shareWith, removedList) {
    return folderAPI.shareFile(id, shareWith, removedList);
  },
  shareFolder(id, shareWith, removedList) {
    return folderAPI.shareFolder(id, shareWith, removedList);
  },
  getAllUsers() {
    return docstoreUserAPI.getAllUsers();
  },
  getFileIcon(filename) {
    let type = filename.split(".").pop();
    switch (type) {
      case "pdf":
        return pdfIcon;
      case "txt":
        return txtIcon;
      case "jpg":
      case "jpeg":
        return jpgIcon;
      case "xls":
      case "xlsx":
        return xlsIcon;
      case "doc":
      case "docx":
        return docIcon;
      case "png":
        return pngIcon;
      default:
        return emptyIcon;
    }
  },
  downloadFile(s3Url, name) {
    const link = document.createElement("a");
    link.href = s3Url;
    link.download = name;
    link.click();
  },
  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  },
  convertBytes(bytes) {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    } else if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < 1024 * 1024 * 1024) {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return `${(bytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    } else {
      return `${(bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2)} TB`;
    }
  }
};
