import { useTheme } from "@emotion/react";
import { useDateField } from "@mui/x-date-pickers/DateField/useDateField";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import useDelivery from "./hooks/useDelivery";
import { useState } from "react";
import DataTable from "examples/Tables/DataTable";
import dayjs from "dayjs";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const ignoreFilterList = [
  "limit",
  "offset",
  "search",
  "page_no",
];

const Delivery = () => {
  const navigation = useNavigate()

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  const [applyFilterFlag, setApplyFilterFlag] = useState(false);

  const { isLoading, filterOptions, deliveries, columns, pages, filters, setFilters } = useDelivery({
    applyFilterFlag,
  });

  const handleFilterChange = (event, type) => {
    if (
      (event.target.value instanceof Date &&
        !isNaN(event.target.value.getTime())) ||
      dayjs.isDayjs(event.target.value) ||
      type == "Date"
    ) {
      event.target.value = format(event.target.value, "yyyy-MM-dd");
    }
    if (Array.isArray(event.target.value) && event.target.value.length > 0) {
      let index = event.target.value.length - 1;
      let value = event.target.value[event.target.value.length - 1];
      if (typeof value == typeof {}) {
        event.target.value[index] = value.email_id
          ? value.email_id
          : value._id
          ? value._id
          : "";
      }
    }
    if (
      Array.isArray(event.target.value) &&
      event.target.value.includes("All")
    ) {
      event.target.value = [];
    }
    let tempFilters = { ...filters, [event.target.name]: event.target.value };

    if (event.target.name == "limit") {
      tempFilters["offset"] = 0;
    } else if (
      typeof event.target.value == "list" &&
      event.target.value?.includes("All")
    ) {
      tempFilters[event.target.name] = [];
    }

    setFilters(tempFilters);
  };

  let debounceTimer;

  const handleSearchChange = (text, type) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setFilters((prevValue) => {
        return { ...prevValue, search: text };
      });
      setApplyFilterFlag(Math.random());
    }, 300);
  };

  const onRowClick = (row) => {
    navigation("/delivery/"+row.values._id.props.data)
  }

  const clearAllFilter = () => {
    setFilters(initialFilters)
    handleClickApplyFilter()
  }
  const handleClickApplyFilter = () => {
    setApplyFilterFlag(Math.random());
  };

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              menu
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>Deliveries</MDBox>
        }
      />
      <MDBox
        sx={{
          position: "relative",
        }}
      >
        <DataTable
          table={{ columns: columns, rows: deliveries || [] }}
          isSorted={true}
          entriesPerPage={{ defaultValue: filters?.limit }}
          showTotalEntries={true}
          noEndBorder
          canSearch={true}
          rowClick={onRowClick}
          filters={filters}
          filterOptions={filterOptions}
          handleFilterChange={handleFilterChange}
          clearAllFilter={clearAllFilter}
          handleSearchChange={(text) => {handleSearchChange(text)}}
          downloadable={false}
          customPagination={true}
          pageInfo={pages}
          isLoading={isLoading}
          handleClickApplyFilter={handleClickApplyFilter}
          tableHeight={"calc(100vh - 184px)"}
          ignoreFilterList={ignoreFilterList}
        />
      </MDBox>
    </DashboardLayout>
  );
};

export default Delivery;
