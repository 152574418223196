import { Checkbox, Icon, Table, TableCell, TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import QuickActionPopup from "components/quickActionPopup/QuickActionPopup";
import { formatDate } from "examples/Tables/DataTable/utils";
import TextWithIcon from "./TextWithIcon";
import { folderService } from "../service/folderService";
import { useTheme } from "@emotion/react";
import { ListViewSkeleton } from "skeletons/folder/folderSkeleton";

const ListView = ({
  showMultiSelect,
  selectedItems,
  setSelectedItems,
  foldersAndFiles,
  handleSelect,
  handleFolderClick,
  setPreviewFile,
  setShowShareWithDialog,
  setShowDeleteFileDialog,
  setShowDeleteFolderDialog,
  isLoading,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, text, warning, error, success, grey } = palette;
  const { pxToRem } = functions;

  return (
    <Table
      sx={{
        width: "100%",
        color: text.main,
        borderCollapse: "collapse",
        "& td, & th": {
          padding: "10px 0px",
          textAlign: "left",
          fontSize: "14px",
          borderBottom: "1px solid " + grey[300],
          cursor: "pointer",
        },
      }}
    >
      <TableRow>
        {showMultiSelect && (
          <TableCell sx={{ width: "35px" }}>
            <Checkbox
              sx={{
                height: "10px",
              }}
              checked={
                selectedItems.files.length + selectedItems.folders.length ===
                foldersAndFiles?.subfolders.length +
                  foldersAndFiles?.files.length
              }
              onChange={(e, value) => {
                value
                  ? handleSelect("", "", "all")
                  : setSelectedItems({ files: [], folders: [] });
              }}
            />
          </TableCell>
        )}
        <TableCell
          sx={{ fontWeight: "bold", color: text.secondary, width: "50%" }}
        >
          NAME
        </TableCell>
        <TableCell sx={{ fontWeight: "bold", color: text.secondary }}>
          UPLOADED ON
        </TableCell>
        <TableCell sx={{ fontWeight: "bold", color: text.secondary }}>
          SIZE
        </TableCell>
        <TableCell />
      </TableRow>
      {isLoading ? (
        <ListViewSkeleton />
      ) : (
        <>
          {foldersAndFiles?.subfolders.map((data) => (
            <TableRow key={data.folder_id}>
              {showMultiSelect && (
                <TableCell sx={{ width: "35px" }}>
                  <Checkbox
                    sx={{
                      height: "10px",
                    }}
                    checked={selectedItems.folders.includes(data.folder_id)}
                    onChange={(e, value) => {
                      handleSelect(value, data.folder_id, "folders");
                    }}
                  />
                </TableCell>
              )}
              <TableCell sx={{ width: "50%" }}>
                <MDBox
                  color={"inherit"}
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={() => handleFolderClick(data.folder_id)}
                >
                  <Icon
                    sx={{
                      fontSize: "1.2em !important",
                      paddingBottom: "5px",
                      color: text.disabled,
                    }}
                  >
                    folder
                  </Icon>
                  <MDBox color={"inherit"}>{data.folder_name}</MDBox>
                </MDBox>
              </TableCell>
              <TableCell>{formatDate(data.created_at)}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <QuickActionPopup
                  icon={
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: text.disabled,
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                    >
                      more_vert
                    </span>
                  }
                  sx={{
                    right: "18px",
                    top: "18px",
                  }}
                >
                  <TextWithIcon
                    onClick={() => {
                      setShowShareWithDialog(data);
                    }}
                    iconName={"group_add"}
                    title={"Share Folder"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  />
                  <TextWithIcon
                    onClick={() => {
                      setShowDeleteFolderDialog(data);
                    }}
                    iconName={"delete"}
                    title={"Delete Folder"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  />
                </QuickActionPopup>
              </TableCell>
            </TableRow>
          ))}
          {foldersAndFiles?.files.map((data) => (
            <TableRow key={data.file_id}>
              {showMultiSelect && (
                <TableCell>
                  <Checkbox
                    sx={{
                      height: "10px",
                    }}
                    checked={selectedItems.files.includes(data.file_id)}
                    onChange={(e, value) => {
                      handleSelect(value, data.file_id, "files");
                    }}
                  />
                </TableCell>
              )}
              <TableCell>
                <MDBox
                  color={"inherit"}
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={() =>
                    setPreviewFile({
                      name: data.filename,
                      url: data.s3_url,
                      id: data.file_id,
                      rawFile: data?.rawFile,
                    })
                  }
                >
                  <img
                    src={folderService.getFileIcon(data.filename)}
                    style={{ height: "1.3em" }}
                  />
                  <MDBox color={"inherit"}>{data.filename}</MDBox>
                </MDBox>
              </TableCell>
              <TableCell>{formatDate(data.created_at)}</TableCell>
              <TableCell>2mb</TableCell>
              <TableCell>
                <QuickActionPopup
                  icon={
                    <span
                      class="material-symbols-outlined"
                      style={{
                        color: text.disabled,
                        cursor: "pointer",
                        fontSize: "1.3em",
                      }}
                    >
                      more_vert
                    </span>
                  }
                  sx={{
                    right: "18px",
                    top: "18px",
                  }}
                >
                  <TextWithIcon
                    onClick={() => {
                      folderService.downloadFile(data.s3_url);
                    }}
                    iconName={"download"}
                    title={"Download File"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  />
                  {/* <TextWithIcon
                    onClick={() => {
                      setShowShareWithDialog(data);
                    }}
                    iconName={"group_add"}
                    title={"Share File"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  /> */}
                  <TextWithIcon
                    onClick={() => {
                      setShowDeleteFileDialog(data);
                    }}
                    iconName={"delete"}
                    title={"Delete File"}
                    pb={"2px"}
                    sx={{
                      padding: "5px 1rem",
                      transition: "background-color .3s",
                      "&:hover": {
                        backgroundColor: grey[200],
                      },
                    }}
                  />
                </QuickActionPopup>
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </Table>
  );
};

export default ListView;
