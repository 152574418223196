import { useGlobalState } from "Global";
import AccessDenied from "layouts/authentication/access-denied";
import React, { useEffect, useState } from "react";

function AccessWrapper(WrappedComponent, permissions) {
  function HOC(props) {
    const [userDetails] = useGlobalState("userDetails");
    const [canAccess, setCanAccess] = useState(true);
    useEffect(() => {
      if (permissions.includes(userDetails.permission)) {
        setCanAccess(false);
      }
    }, []);
    return (
      <>
        {canAccess==true && <AccessDenied />}
        {!canAccess==true && <WrappedComponent {...props} />}
      </>
    );
  }
  return HOC;
}
export default AccessWrapper;
