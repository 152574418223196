import { useEffect, useState } from "react";
import { emailService } from "../service/emailService";

const columnData = [
  {
    accessor: "_id",
    type: "id",
    show: false,
  },
  {
    accessor: "from",
    type: "text",
  },
  {
    accessor: "preview",
    type: "text",
  },
  {
    Header: "date",
    accessor: "created_at",
    type: "text",
  },
  {
    accessor: "attachments",
    show: false,
  },
  {
    accessor: "bcc",
    show: false,
  },
  {
    accessor: "cc",
    show: false,
  },
  {
    accessor: "chain_emails",
    show: false,
  },
  {
    accessor: "content",
    show: false,
  },
  {
    accessor: "message_id",
    show: false,
  },
  {
    accessor: "references",
    show: false,
  },
  {
    accessor: "subject",
    show: false,
  },
  {
    accessor: "to",
    show: false,
  },
];

const useEmail = ({ filters, applyFilterFlag }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [pageInfo, setPageInfo] = useState({});

  useEffect(() => {
    setIsLoading(true);
    emailService
      .getAllEmails({...filters, page_no: filters.offset + 1})
      .then((res) => {
        if (res.status === 200) {
          const tempData = res.data.data.map((data) => ({
            ...data,
            preview: data?.content?.plain_text.slice(0, 100) + "...",
          }));
          setPageInfo({ count: res.data.total_count, pages: Math.ceil(res.data.total_count / filters.limit) });
          setRows(tempData);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [applyFilterFlag]);

  return {
    isLoading,
    emailDetails: rows,
    columns: columnData,
    pages: pageInfo,
  };
};

export default useEmail;
