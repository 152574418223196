import { useTheme } from "@emotion/react";
import Icon from "@mui/material/Icon";
import { docstoreUserAPI } from "api";
import MDBox from "components/MDBox";
import { OutlinedButton } from "components/MDButton/button";
import DataInputDialog from "examples/dialogs/DataInputDialog";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import DataTable from "examples/Tables/DataTable";
import { formatDate } from "examples/Tables/DataTable/utils";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useUsers from "./hooks/useUsers";
import { setGlobalState } from "Global";

const ignoreColumns = ["_id", "members", "sub_users", "parent_user"];

function UserManagement() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddUser, setShowAddUser] = useState(false);

  const selectedUserRef = useRef(null);

  const { columns, users, setUsers, setIsLoading, isLoading, teamList } =
    useUsers();
  console.log(teamList);
  const tabs = [
    {
      name: "User",
      route: "user",
    },
    {
      name: "Team",
      route: "team",
    },
  ];

  const defaultInputs = {
    username: {
      type: "Text",
      editable: true,
      required: true,
    },
    email_id: {
      type: "Text",
      editable: true,
      required: true,
    },
    name: {
      type: "Text",
      editable: true,
      required: true,
    },
    permission: {
      type: "DropdownSingleSelect",
      editable: true,
      options: ["user", "admin", "superuser"],
    },
    team_id: {
      label: "Team",
      type: "DropdownSingleSelect",
      editable: true,
      options: teamList,
      dataKey: "_id",
      labelKey: "team_name",
    },
  };

  const handleRowClick = (row) => {
    selectedUserRef.current = defaultInputs;
    selectedUserRef.current["username"].editable = false;
    selectedUserRef.current["email_id"].editable = false;
    Object.keys(defaultInputs).map((key) => {
      selectedUserRef.current[key].value = row.values[key];
    });
    setSelectedUser(row.values);
  };

  const handleUpdateUser = (id, data) => {
    setIsLoading(true);
    let updatedData = {};
    Object.keys(data).forEach((key) => {
      if(data[key] !== selectedUser[key]) updatedData[key] = data[key]
    })
    docstoreUserAPI
      .updateUser({id , email_id: data.email_id, ...updatedData})
      .then((res) => {
          if (res.status === 200) {
            const tempData = users.map((data) => {
              if (id === data._id) {
                return { ...data, ...updatedData };
              }
              return data;
            });
            setGlobalState("error", {
              open: true,
              message: "User data updated successfully!",
              type: "success",
              code: 200,
            });
            setUsers(tempData);
          }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setSelectedUser(null);
      });
  };

  const handleCreateNewUser = (data) => {
    setIsLoading(true);
    const { email_id, name, permission, username, team_id } = data;
    docstoreUserAPI
      .createUser(email_id, name, permission, username, team_id)
      .then((res) => {
        if (res.status === 200) {
          setUsers((pre) => [
            ...pre,
            {
              ...data,
              created_at: formatDate(new Date()),
              updated_at: formatDate(new Date()),
              members_count: data.members.length,
              _id: res.data.user_id,
            },
          ]);
          setGlobalState("error", {
            open: true,
            message: "New user created successfully!",
            type: "success",
            code: 200,
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
        setShowAddUser(false);
      });
  };

  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              manage_accounts
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            User Management
          </MDBox>
        }
        rightContent={
          <OutlinedButton
            name={"Add User"}
            onClick={() => {
              setShowAddUser(true);
              setSelectedUser(null);
            }}
            icon={
              <Icon className={"font-unset"} fontSize="1em">
                add
              </Icon>
            }
          />
        }
      />
      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          borderBottom: `1px solid ${palette.grey[300]}`,
          gap: "1em",
        }}
        mb={2}
      >
        {tabs.map((tab, index) => (
          <Link to={"/management/" + tab.route}>
            <MDBox
              key={index}
              sx={{
                padding: pxToRem(8),
                cursor: "pointer",
                gap: "5px",
                display: "flex",
                paddingBottom: pxToRem(5),
                paddingTop: 0,
                borderBottom:
                  tab?.name === "User"
                    ? `2px solid ${palette.primary.main}`
                    : ``,
              }}
              color={palette.text.header}
            >
              {tab?.name}
            </MDBox>
          </Link>
        ))}
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns: columns, rows: users }}
          isLoading={isLoading}
          downloadable={true}
          canSearch={true}
          ignoreColumns={ignoreColumns}
          rowClick={handleRowClick}
          tableHeight={"calc(100vh - 231px)"}
          entriesPerPage={{ defaultValue: 10 }}
          isSorted={true}
          showTotalEntries={true}
          customPagination={false}
        />
      </MDBox>
      {selectedUser && (
        <DataInputDialog
          open={!!selectedUser}
          handleClose={() => {
            setSelectedUser(null);
          }}
          defaultInputs={selectedUserRef.current}
          handleSubmit={(data) => {
            handleUpdateUser(selectedUser._id, data);
          }}
          buttonName={"Update"}
          heading={"Update User Details"}
          iconName={"group"}
          isLoading={isLoading}
          doubleColAfterInputs={5}
        />
      )}
      {showAddUser && (
        <DataInputDialog
          open={showAddUser}
          handleClose={() => {
            setShowAddUser(false);
          }}
          defaultInputs={defaultInputs}
          handleSubmit={(data) => {
            handleCreateNewUser(data);
          }}
          buttonName={"Create User"}
          heading={"Create New User"}
          iconName={"group_add"}
          isLoading={isLoading}
          doubleColAfterInputs={5}
        />
      )}
    </DashboardLayout>
  );
}

export default UserManagement;
