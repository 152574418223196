/*
 * This file serves as a base file for any API interactions with other applications.
 * All API calls, declarations, definitions are included here.
 */
import axios from "axios";
import useWebSocket from "react-use-websocket";

const url = "";
const webSocketUrl = process.env.REACT_APP_API_URL_WS;

export let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL_MAIN,
  withCredentials: true,
});

export let docstoreClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_DOCSTORE,
  withCredentials: true,
});

export const basicAPI = {
  signin(username, password) {
    const params = { username: username, password: password };
    return client.post(`${url}/signin`, null, { params: params });
  },

  signinNew(username, password) {
    const data = { username: username, password: password };
    return client.post(`${url}/signin_new`, data);
  },

  signinKey() {
    return client.get(`${url}/signin_new/public_key`);
  },

  login() {
    return client.get(`${url}/login`);
  },

  logout() {
    return client.get(`${url}/logout`);
  },

  userData() {
    return client.get(`${url}/me`);
  },

  refreshToken() {
    return client.get(`${url}/me`);
  },

  loginCallback(code) {
    const params = { code: code };
    return client.get(`${url}/login/callback`, { params: params });
  },
};

export const agentAPI = {
  // New api
  getAllCases(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases`, { params: params });
  },

  getAllCasesAdmin(paramsData) {
    const params = paramsData;
    return client.get(`${url}/get_all_cases_admin`, { params: params });
  },

  getAgentDetails(agentId) {
    const params = { agent_id: agentId };
    return client.get(`${url}/get_single_agent_details`, { params: params });
  },
};

export const userAPI = {
  getUserDetails() {
    return client.get(`${url}/get_current_user_details`);
  },

  updateUserDetails(userDetails) {
    return client.post(`${url}/update_user_details`, null, {
      params: userDetails,
    });
  },
};

//signup-auth

export const SignUpAPI = {
  signUpEmail(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify`,
      method: "POST",
      data,
    });
  },
  signUpEmailResend(email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/resend`,
      method: "POST",
      data,
    });
  },
  signUpEmailOTP(params, email) {
    const data = { value: email };
    return client({
      url: `${url}/sign-up/emailverify/otp`,
      method: "POST",
      data,
      params: params,
    });
  },
  signUpEmailPasswd(params, email) {
    const data = { value: email };
    console.log(data, params);
    return client({
      url: `${url}/sign-up/emailverify/otp/setpasswd`,
      method: "POST",
      data,
      params: params,
    });
  },
};

// New application apis
export const DashboardAPI = {
  getFilterValues(params) {
    return client.post(`${url}/filter/common`, null, { params: params });
  },
  getFilterNames() {
    return client.post(`${url}/filter/get_all_filter_names`);
  },

  // Base Analytics
  getOTDBASEDataTile(params, data) {
    return client.post(`${url}/analytics/main/otd-base/tile`, data, {
      params: params,
    });
  },

  getOTDPHCDataTile(params, data) {
    return client.post(`${url}/analytics/main/otd-phc/tile`, data, {
      params: params,
    });
  },
  getOTCBASEDataTile(params, data) {
    return client.post(`${url}/analytics/main/otc-base/tile`, data, {
      params: params,
    });
  },

  getOTCDSADAKARDataTile(params, data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/tile`, data, {
      params: params,
    });
  },
  getVFRDataTile(params, data) {
    return client.post(`${url}/analytics/main/vfr/tile`, data, {
      params: params,
    });
  },

  getOTDBASEDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otd-base/trend`, data, {
      params: params,
    });
  },

  getOTDPHCDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otd-phc/trend`, data, {
      params: params,
    });
  },
  getOTCBASEDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otc-base/trend`, data, {
      params: params,
    });
  },

  getOTCDSADAKARDataTrend(params, data) {
    return client.post(`${url}/analytics/main/otc-dsadakar/trend`, data, {
      params: params,
    });
  },
  getVFRDataTrend(params, data) {
    return client.post(`${url}/analytics/main/vfr/trend`, data, {
      params: params,
    });
  },

  getTrendGroupBy(params, data) {
    return client.post(`${url}/analytics/main/trend/groupby_names`, data, {
      params: params,
    });
  },

  // Dedicated breakdown
  getOTDBASEDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otd-base`, data, {
      params: params,
    });
  },
  getOTDPHCDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otd-phc`, data, {
      params: params,
    });
  },
  getOTCBASEDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otc-base`, data, {
      params: params,
    });
  },
  getOTCDSADAKARDataSub(params, data) {
    return client.post(`${url}/analytics/sub/otc-dsadakar`, data, {
      params: params,
    });
  },
  getVFRDataSub(params, data) {
    return client.post(`${url}/analytics/sub/vfr`, data, { params: params });
  },

  getGroupByOTD(params, data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`, data, {
      params: params,
    });
  },

  getGroupByOTC(params, data) {
    return client.post(`${url}/analytics/sub/otdotc/groupby_names`, data, {
      params: params,
    });
  },

  getGroupByVFR(params, data) {
    return client.post(`${url}/analytics/sub/vfr/groupby_names`, data, {
      params: params,
    });
  },

  // OTD New Analytics

  getOTDGroupBy(params, data) {
    return client.post(`${url}/analytics/otd/groupby_names`, data, {
      params: params,
    });
  },

  getOTDTrend(params, data) {
    return client.post(`${url}/analytics/otd/trend`, data, { params: params });
  },

  getOTDTrendGroupBy(params, data) {
    return client.post(`${url}/analytics/otd/trend/groupby_names`, data, {
      params: params,
    });
  },

  // OTD (Domestic) Analytics
  getOTDDomesticCustomer(params, data) {
    return client.post(`${url}/analytics/otd/domestic/customer_wise`, data, {
      params: params,
    });
  },

  getOTDDomesticDeliveryRegion(params, data) {
    return client.post(`${url}/analytics/otd/domestic/region_wise`, data, {
      params: params,
    });
  },

  getOTDDomesticTrend(params, data) {
    return client.post(`${url}/analytics/otd/domestic/trend`, data, {
      params: params,
    });
  },
  getOTDDomesticOverall(params, data) {
    return client.post(`${url}/analytics/otd/domestic/overall`, data, {
      params: params,
    });
  },

  // OTD (Export) Analytics
  getOTDExportCustomer(params, data) {
    return client.post(`${url}/analytics/otd/export/customer_wise`, data, {
      params: params,
    });
  },

  getOTDExportOffloadingPoint(params, data) {
    return client.post(
      `${url}/analytics/otd/export/offloading_point_wise`,
      data,
      { params: params }
    );
  },

  getOTDExportTrend(params, data) {
    return client.post(`${url}/analytics/otd/export/trend`, data, {
      params: params,
    });
  },

  getOTDExportOverall(params, data) {
    return client.post(`${url}/analytics/otd/export/overall`, data, {
      params: params,
    });
  },

  // VFR Analytics
  getVFRCustomer(params, data) {
    return client.post(`${url}/analytics/vfr/customer_wise`, data, {
      params: params,
    });
  },
  getVFRRegion(params, data) {
    return client.post(`${url}/analytics/vfr/region_wise`, data, {
      params: params,
    });
  },
  getVFROverall(params, data) {
    return client.post(`${url}/analytics/vfr/overall`, data, {
      params: params,
    });
  },
  getVFRTrend(params, data) {
    return client.post(`${url}/analytics/vfr/trend`, data, { params: params });
  },
};

export const ExcelDataAPI = {
  excelUpload(params, data) {
    return client({
      url: `${url}/excel_upload`,
      method: "POST",
      params: params,
      data,
      headers: { "content-type": "multipart/form-data" },
    });
  },
  getReferences() {
    return client.post(`${url}/get_supported_references`);
  },
  getMainTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_main_table`, data, { params: params });
  },

  updateMainRecords(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/update_records`, data, { params: params });
  },

  deleteMainRecord(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/delete_record`, data, { params: params });
  },
  getSuccessTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_success_table`, data, { params: params });
  },
  getFailTable(paramsData, data) {
    const params = paramsData;
    return client.post(`${url}/get_fail_table`, data, { params: params });
  },
};

// docstore api

export const folderAPI = {
  getFolderAndFilesDetails(data) {
    return docstoreClient.post(`${url}/folders/list_contents`, data);
  },
  getFolderDetails(id) {
    return docstoreClient.post(`${url}/folders/get_folder`, { folder_id: id });
  },
  getFileDetails(id) {
    return docstoreClient.post(`${url}/files/get_file`, { file_id: id });
  },
  createFolder(data) {
    return docstoreClient.post(`${url}/folders/create_folder`, data);
  },
  deleteFolder(data) {
    return docstoreClient.post(`${url}/folders/delete_folder`, data);
  },
  deleteFile(data) {
    return docstoreClient.post(`${url}/files/delete_file`, data);
  },
  uploadFile(data) {
    return docstoreClient({
      url: `${url}/folders/upload_file`,
      method: "POST",
      data,
      headers: { "content-type": "multipart/form-data" },
    });
  },
  searchFiles(query, isVectorSearch = false) {
    return docstoreClient.post(`${url}/files/search`, {
      search_query: query,
      vector_search: isVectorSearch,
    });
  },
  searchFilesWS(params) {
    return new useWebSocket(`${webSocketUrl}/files/search/ws`, {
      queryParams: params,
    });
  },
  shareFolder(id, shareWith = [], removedList = []) {
    return docstoreClient.post(`${url}/folders/share_folder`, {
      folder_id: id,
      shared_with: shareWith,
      removed_list: removedList,
    });
  },
  shareFile(id, shareWith = [], removedList = []) {
    return docstoreClient.post(`${url}/files/share_file`, {
      file_id: id,
      shared_with: shareWith,
      removed_list: removedList,
    });
  },
};

export const docstoreUserAPI = {
  getAllUsers() {
    return docstoreClient.post(`${url}/users/get_all`);
  },
  createUser(email, name, permission, username, teamId) {
    return docstoreClient.post(`${url}/users/create_user`, {
      email_id: email,
      username: username,
      name: name,
      permission: permission,
      team_id: teamId,
    });
  },
  updateUser(data) {
    return docstoreClient.post(`${url}/users/update_user`, data);
  },
};

export const teamsManagementAPI = {
  getAllTeams() {
    return docstoreClient.post(`${url}/teams/get_all_teams`, {});
  },
  createTeam(name, parentTeam, members, subTeams) {
    return docstoreClient.post(`${url}/teams/create_team`, {
      team_name: name,
      parent_team: parentTeam,
      members: members || [],
      sub_teams: subTeams || [],
    });
  },
  updateTeam(id, members, name) {
    return docstoreClient.post(`${url}/teams/update_team`, {
      team_id: id,
      members: members,
      team_name: name,
    });
  },
};

export const integrationAPI = {
  getAllEmails(data) {
    return docstoreClient.post(`${url}/integrations/emails`, data);
  },
  getAttachmentDetails(ids) {
    return docstoreClient.post(`${url}/integrations/emails/get_attachments`, {
      attachment_ids: ids,
    });
  },
};

export const deliveryAPI = {
  getDeliveryData(data) {
    return docstoreClient.post(`${url}/data_view`, data);
  },
  getDeliveryDetails(id) {
    return docstoreClient.post(`${url}/data_view/get_details`, {
      filters: { _id: id },
    });
  },
  updateDeliveryDetails(id, data) {
    return docstoreClient.post(`${url}/data_view/update_details`, {
      filters: { _id: id },
      field_values: data
    });
  },
  getDeliveryFilterOptions() {
    return docstoreClient.post(`${url}/data_view/filters`, {});
  },
};
