import { teamsManagementAPI } from "api";
import { formatDate } from "examples/Tables/DataTable/utils";
import { folderService } from "layouts/folders/service/folderService";
import { useEffect, useState } from "react";

const columnData = [
  {
    accessor: "_id",
    type: "id",
  },
  {
    accessor: "team_name",
    type: "text",
  },
  {
    accessor: "parent_team",
    type: "text",
  },
  {
    accessor: "members_count",
    type: "text",
  },
  {
    accessor: "created_at",
    type: "text",
  },
  {
    accessor: "updated_at",
    type: "text",
  },
];

const useTeams = () => {
  const [teamList, setTeamList] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllTeams = () => {
    setIsLoading(true)
    teamsManagementAPI
      .getAllTeams()
      .then((res) => {
        if (res.status == 200) {
          const tempdata = res.data.map((data) => ({
            ...data,
            created_at: formatDate(data.created_at),
            updated_at: formatDate(data.updated_at),
            members_count: data.members.length,
          }));
          setTeamList(tempdata);
        }
      })
      .catch(() => {}).finally(() => {
        setIsLoading(false)
      });
  };

  const getUsers = () => {
    folderService
      .getAllUsers()
      .then((res) => {
        if (res.status == 200) {
          setUsers(res.data);
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getAllTeams();
    getUsers();
  }, []);

  return {
    columns: columnData,
    teamList: teamList,
    users,
    setTeamList,
    setIsLoading,
    isLoading,
  };
};

export default useTeams;
