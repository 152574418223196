import { AppBar, Toolbar } from "@mui/material";
import MDBox from "components/MDBox";
import { useStyles } from "./styles";

const TopNavbar = ({ icon, leftContent, rightContent }) => {
  const styles = useStyles();
  return (
    <AppBar position={"sticky"} color="white" sx={styles.navbarContainer} >
      <Toolbar sx={styles.flexBox}>
        <MDBox sx={styles.leftCard}>
          {icon}
          {leftContent}
        </MDBox>
        {rightContent}
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
