import { deliveryAPI } from "api";

export const deliveryService = {
  getDeliveryData(data) {
    return deliveryAPI.getDeliveryData(data);
  },
  getDeliveryFilterOptions() {
    return deliveryAPI.getDeliveryFilterOptions();
  },
};
