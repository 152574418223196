import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TopNavbar from "examples/Navbars/TopNavbar";
import { useStyles } from "examples/Navbars/TopNavbar/styles";
import { Outlet, useLocation } from "react-router-dom";
import IconCard from "./components/IconCard";

const Integration = () => {
  const pathLenght = useLocation()
    .pathname.split("/")
    .filter((data) => data).length;
  const styles = useStyles();

  const colors = ["#666CFF", "#FF7B70", "#FEB686", "#34D7B0"];

  const cards = [
    {
      title: "File Upload",
      path: "file-upload",
      iconName: "upload",
    },
    {
      title: "Email",
      path: "email",
      iconName: "email",
    },
    {
      title: "Sharepoint",
      path: "",
      iconName: "share",
    },
    {
      title: "Drive",
      path: "",
      iconName: "drive_export",
    },
    {
      title: "3rd party",
      path: "",
      iconName: "track_changes",
    },
  ];

  return pathLenght === 1 ? (
    <DashboardLayout>
      <TopNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              widgets
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Integrations
          </MDBox>
        }
      />
      <MDBox
        sx={{
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: "repeat(6, 1fr)",
          // "@media (max-width: 1115px)": {
          //   gridTemplateColumns: "repeat(8, 1fr)",
          // },
          // "@media (max-width: 1115px)": {
          //   gridTemplateColumns: "repeat(8, 1fr)",
          // },
          "@media (max-width: 1050px)": {
            gridTemplateColumns: "repeat(5, 1fr)",
          },
          "@media (max-width: 800px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@media (max-width: 500px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        }}
      >
        {cards.map((card, index) => (
          <IconCard
            title={card.title}
            path={card.path}
            iconColor={colors[index % colors.length]}
            iconName={card.iconName}
          />
        ))}
      </MDBox>
    </DashboardLayout>
  ) : (
    <Outlet />
  );
};

export default Integration;
