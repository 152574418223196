import Icon from "@mui/material/Icon";
import AccessDenied from "layouts/authentication/access-denied";
import Bulk from "layouts/bulkupload/index";
import Dashboard from "layouts/dashboard";
import Logout from "layouts/logout";
import Tables from "layouts/tables";
import Folders from "layouts/folders/Folders";
import { Navigate } from "react-router-dom";
import TeamManagement from "layouts/management/teamManagement/TeamManagement";
import UserManagement from "layouts/management/userManagement/UserManagement";
import Integration from "layouts/integration/Integration";
import Email from "layouts/integration/email/Email";
import Delivery from "layouts/delivery/Delivery";
import DeliveryDetails from "layouts/deliveryDetails/DeliveryDetails";

export const defaultRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        dashboard
      </Icon>
    ),
    route: "/dashboard",
    path: "/dashboard",
    access: ["agent", "admin", "agent2", "superuser"],
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "DeliveriesData",
    key: "deliveriesData",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        table_view
      </Icon>
    ),
    path: "/deliveriesData",
    route: "/deliveriesData",
    access: ["agent", "admin", "superuser"],
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Deliveries",
    key: "deliveries",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        menu
      </Icon>
    ),
    path: "/deliveries",
    route: "/deliveries",
    access: ["agent", "admin", "superuser"],
    component: <Delivery />,
  },
  {
    type: "",
    name: "Delivery",
    key: "delivery",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        menu
      </Icon>
    ),
    path: "/delivery/:id",
    route: "/delivery",
    access: ["agent", "admin", "superuser"],
    component: <DeliveryDetails />,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "folders",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        folder
      </Icon>
    ),
    route: "/folders",
    path: "/folders",
    subRoute: [
      {
        path: ":id",
        component: <Folders />,
      },
    ],
    access: ["agent1", "admin", "agent2", "superuser", "user"],
    component: <Folders />,
  },
  {
    type: "collapse",
    name: "Integrations",
    key: "integration",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        widgets
      </Icon>
    ),
    route: "/integrations",
    path: "/integrations",
    subRoute: [
      {
        path: "file-upload",
        component: <Bulk />,
      },
      {
        path: "email",
        component: <Email />,
      },
    ],
    access: ["agent1", "admin", "agent2", "superuser", "user"],
    component: <Integration />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "management",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        group
      </Icon>
    ),
    route: "/management",
    path: "/management",
    access: ["agent1", "admin", "agent2", "superuser", "user"],
    component: <Navigate to={"/management/user"} />,
  },
  {
    type: "",
    key: "teamManagement",
    route: "/management/team",
    path: "/management/team",
    access: ["agent1", "admin", "agent2", "superuser", "user"],
    component: <TeamManagement />,
  },
  {
    type: "",
    key: "userManagement",
    route: "/management/user",
    path: "/management/user",
    access: ["agent1", "admin", "agent2", "superuser", "user"],
    component: <UserManagement />,
  },
  {
    type: "",
    name: "Logout",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        logout
      </Icon>
    ),
    route: "/logout",
    path: "/logout",
    access: ["agent2", "superuser", "agent", "admin"],

    component: <Logout />,
  },
  {
    type: "",
    name: "Access Denied",
    key: "access-denied",
    icon: (
      <Icon fontSize="medium" className="navbar-icon">
        assignment
      </Icon>
    ),
    route: "/access-denied",
    path: "/access-denied",
    access: ["agent2", "superuser", "agent", "admin"],

    component: <AccessDenied />,
  },
];
