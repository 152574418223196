import { useTheme } from "@emotion/react";
import MDBox from "components/MDBox";
import Chart from "examples/chart/Chart";
import { useEffect, useRef, useState } from "react";
import { downloadImage, getFiltersCount } from "../../../docDashboard/utils";
import ChartHolderPopup from "layouts/docDashboard/components/ChartHolderPopup";
import { format } from "date-fns";

const ignoreFilterList = [];

const ChartHolder = ({
  title,
  children,
  chartProps,
  initialFilters,
  getData,
  id,
  showFilter,
  filterOptions,
}) => {
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;
  const storedFilters = {};
  const [chartData, setChartData] = useState(null);
  const [filters, setFilters] = useState(storedFilters?.[id] || initialFilters);
  const [filtersCount, setFiltersCount] = useState(0);
  const [tempFilters, setTempFilters] = useState(
    storedFilters?.[id] || initialFilters
  );
  const [isPopupActive, setIsPopupActive] = useState(false);

  useEffect(() => {
    setTempFilters(initialFilters);
    setFilters(initialFilters);
  }, [filterOptions]);

  const tabs = [
    "This Week",
    "This Quarter",
    "Last 6 Months",
    "This Year",
    "Clear",
  ];
  const [activeTab, setActiveTab] = useState("");
  const imageRef = useRef(null);

  const handleSelectTab = (tab, dateRange) => {
    if (tab === "Clear") {
      setActiveTab("");
      setFilters((pre) => ({ ...pre, from_date: "", to_date: "" }));
    } else {
      setActiveTab(tab);
      setFilters((pre) => ({
        ...pre,
        from_date: dateRange?.from || "",
        to_date: dateRange?.to || "",
      }));
    }
  };
  const getChartData = () => {
    setFiltersCount(getFiltersCount(tempFilters, ignoreFilterList));
    getData({ ...tempFilters, type: tempFilters?.type[0] || "weekly" }, { ...tempFilters, type: tempFilters?.type[0] || "weekly" })
      ?.then((res) => {
        setChartData(res.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setTempFilters(filters);
    if (getData && filters) {
      getChartData();
    }
  }, [getData]);

  const handleFilterChange = (event, type) => {
    let { name, value } = event.target;

    if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      type == "Date"
    ) {
      value = format(value, "yyyy-MM-dd");
    }

    let tempBasicFilter = {
      ...tempFilters,
      [name]: value,
    };
    setTempFilters(tempBasicFilter);
  };

  const handleClosePopup = () => {
    setIsPopupActive(false);
    setTempFilters(filters);
  };

  const handleApplyFiter = () => {
    setFilters(tempFilters);
    getChartData();
  };

  const handleClick = () => {
    setIsPopupActive(true);
  };

  return (
    <MDBox
      sx={{
        height: "100%",
        width: "100%",
        padding: "1em",
        position: "relative",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <MDBox color={text.main}>{title || chartData?.graph.title}</MDBox>
        <MDBox sx={{ display: "flex", alignItems: "center", gap: "2em" }}>
          <MDBox sx={{ display: "flex", alignItems: "center", gap: ".5em" }}>
            {/* {showFilter !== false && <MDBox
              sx={{
                backgroundColor: primary.light,
                fontWeight: typography.fontWeightMedium,
                borderRadius: "1em",
                width: "40px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                cursor: "pointer",
              }}
              color={text.disabled}
            >
              1M
            </MDBox>} */}
          </MDBox>

          <MDBox
            sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}
            onClick={handleClick}
          >
            {showFilter !== false && (
              <>
                <MDBox sx={{ position: "relative" }}>
                  {filtersCount !== 0 && (
                    <MDBox
                      sx={{
                        position: "absolute",
                        backgroundColor: primary.main,
                        top: "-1.5em",
                        right: "-3.2em",
                        transform: "scale(.8)",
                        width: "20px",
                        height: "20px",
                        textAlign: "center",
                        fontSize: "12px",
                        borderRadius: "100px",
                      }}
                      color={white.main}
                    >
                      {filtersCount}
                    </MDBox>
                  )}
                </MDBox>
                <span
                  style={{
                    color: text.disabled,
                    fontSize: "1.5em",
                    cursor: "pointer",
                  }}
                  class="material-symbols-outlined"
                >
                  filter_alt
                </span>
              </>
            )}
            <span
              style={{
                color: text.disabled,
                fontSize: "1.5em",
                cursor: "pointer",
              }}
              class="material-symbols-outlined"
            >
              open_in_full
            </span>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox
        sx={{
          height: "calc(100% - 40px)",
          marginRight: "1.5em",
        }}
        ref={chartProps ? imageRef : null}
      >
        <Chart {...chartProps} data={chartData?.graph.data} />
      </MDBox>
      <MDBox
        sx={{
          position: "absolute",
          top: "100%",
          left: "100%",
          transform: "translate(-2.5em, -2em)",
          width: "1.5em",
          height: "1.5em",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <span
          style={{
            color: text.disabled,
            fontSize: "1.5em",
            cursor: "pointer",
          }}
          class="material-symbols-outlined"
          onClick={() => downloadImage(imageRef.current, title)}
        >
          photo_camera
        </span>
      </MDBox>
      {isPopupActive && (
        <ChartHolderPopup
          title={title || chartData?.graph.title}
          tabs={tabs}
          activeTab={activeTab}
          handleSelectTab={handleSelectTab}
          chart={<Chart {...chartProps} data={chartData?.graph.data} />}
          handleFilterChange={handleFilterChange}
          handleClosePopup={handleClosePopup}
          handleApplyFiter={handleApplyFiter}
          filters={tempFilters}
          filterOptions={filterOptions}
          showFilter={showFilter !== false}
          ignoreFilterList={ignoreFilterList}
        />
      )}
    </MDBox>
  );
};

export default ChartHolder;
