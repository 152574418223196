import { useTheme } from "@emotion/react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CustomPopper } from "./CustomPopper";

export default function Dropdown({
  value,
  onChange,
  options,
  getOptionLabel,
  placeholder,
  label,
  variant,
  dataKey,
  labelKey,
  name,
}) {
  const { palette } = useTheme();
  const { text } = palette;
  return (
    <Autocomplete
      multiple
      value={value}
      id="checkboxes-tags-demo"
      options={options}
      getOptionLabel={(option) => {
        return labelKey && option[labelKey] ? option[labelKey] : option?.name ? option.name : typeof(option) != 'object'? option : '';
      }}
      disableCloseOnSelect
      PopperComponent={CustomPopper}
      sx={{
        margin: '10px 0',
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#F1F2FF",
          color: text.disabled,
        },
        "& svg": {
          fill: text.disabled,
        },
        "& label": {
          lineHeight: "1.2em",
        },
        "& .MuiInputBase-root":{
          padding:'5px'
        }
      }}
      onChange={(e, newValue) => {
        if(typeof(newValue?.[newValue.length - 1]) != 'object')
          onChange({ target: { name: name, value: newValue } });
        else {
          if(dataKey){
            onChange({ target: { name: name, value: newValue.map((data) => data[dataKey] || data) } });
          } else {
            onChange({ target: { name: name, value: newValue}} );
          }
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
}
