import { useTheme } from "@emotion/react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from "recharts";
import { COLORS } from "../js/const";
import PropTypes from "prop-types";

const CustomAreaChart = ({ data, axisStyle, grid, graph, legend, tooltip, isLineChart, name, domain }) => {
  const { palette } = useTheme();

  const keys = Object.keys(data[0]);
  const tname = name || Object.keys(data?.[0])?.[0]
  if(keys.indexOf(tname) !== -1) keys.splice(keys.indexOf(tname), 1);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <AreaChart data={data} height={"100%"}>
        <defs>
          {keys.map((key, index) => (
            <linearGradient id={key} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="10%"
                stopColor={graph?.key?.stroke || COLORS[index] || "#000"}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={graph?.key?.stroke || COLORS[index] || "#000"}
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid strokeDasharray="3 3" {...grid} />
        <XAxis
          dataKey={tname}
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
        />
        <YAxis
          stroke={axisStyle?.stroke || palette.text.light}
          tick={{
            fill: axisStyle?.fontColor || palette.text.disabled,
            fontSize: axisStyle?.fontSize || 12,
          }}
          domain={domain? ["dataMin", "dataMax"] : undefined}
        />
        {tooltip?.show !== false &&<Tooltip
          itemStyle={{
            fontSize: axisStyle?.fontSize || 12,
          }}
          contentStyle={tooltip?.contentStyle}
        />}
        {legend?.show !== false && <Legend
          layout={legend?.layout || 'horizontal'}
          align={legend?.align || 'center'}
          verticalAlign={legend?.verticalAlign || 'bottom'}
          wrapperStyle={{
            fontSize: axisStyle?.fontSize || 12,
            textTransform: 'capitalize'
          }}
        />}
        {keys.map((key, index) => (
          <Area
            dataKey={key}
            type=""
            stroke={COLORS[index] || "#000"}
            strokeWidth={1}
            fillOpacity={0}
            fill={`url(#${key})`}
            {...(graph?.[key] || {})}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

CustomAreaChart.propTypes = {
  axisStyle: PropTypes.shape({
    stroke: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
  }),
  grid: PropTypes.shape({
    horizontal: PropTypes.bool,
    vertical: PropTypes.bool,
  }),
  graph: PropTypes.objectOf(
    PropTypes.shape({
      stroke: PropTypes.string,
      strokeWidth: PropTypes.number,
      fill: PropTypes.string,
      fillOpacity: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  legend: PropTypes.shape({
    align: PropTypes.string,
    show: PropTypes.bool,
  }),
  tooltip: PropTypes.shape({
    contentStyle: PropTypes.object,
    show: PropTypes.bool,
  }),
};

export default CustomAreaChart;
